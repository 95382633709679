<script setup>
import {
  computed,
  inject,
  onMounted,
  ref,
} from 'vue';
import useSystemStore from '@/store/system/system-store';
import useCharacterStore from '@/store/character/character-store';
import useOfflineStore from '@/store/offline-store';
import DeleteDialog from '@/components/DeleteDialog.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import ModdedIcon from '@/components/icons/ModdedIcon.vue';
import CharacterObjectFieldWithCalculations from '@/components/CharacterObjectFieldWithCalculations.vue';
import ListContainer from '@/components/ListContainer.vue';
import ObjectEditDialog from './ObjectEditDialog.vue';

const systemStore = useSystemStore();
const characterStore = useCharacterStore();
const offlineStore = useOfflineStore();

const character = inject('character');
const dialogDelete = ref(false);
const dialog = ref(false);
const loading = ref(false);
const localListDef = ref({});
const characterListID = ref('');
const items = ref([]);
const search = ref('');
const headers = ref([
  {
    title: 'Object',
    align: 'start',
    sortable: true,
    key: 'label',
  }, {
    title: '',
    align: 'end',
    key: 'actions',
    sortable: false,
  },
]);

const defaultItem = {
  character: character.value?._id || character.value,
  characterList: characterListID.value,
  objectDefinition: null,
  label: '',
  description: '',
  quantity: 1,
  fields: {},
  effects: [],
};

const editedItem = ref({ ...defaultItem });

const myProps = defineProps({
  listDef: {
    type: Object,
    required: true,
  },
});

const openAddObjectDialog = (objectDefinition) => {
  editedItem.value = { ...defaultItem };
  editedItem.value.objectDefinition = objectDefinition;
  editedItem.value.fields = {};
  dialog.value = true;
};

const openEditObjectDialog = (list) => {
  editedItem.value = { ...list };
  dialog.value = true;
};

const onRowClick = (event, details) => {
  const { item } = details;
  openEditObjectDialog(item);
};

const closeObjectDialog = () => {
  dialog.value = false;
};

const openDeleteDialog = (item) => {
  editedItem.value = { ...item };
  dialogDelete.value = true;
};

const deleteItem = async () => {
  // const index = system.value.variableDefinitions.findIndex((v) => v.id === editedItem.value.id);
  // await systemStore.deleteVariableDefinition(editedItem.value.id);
  // system.value.variableDefinitions.splice(index, 1);
  const index = items.value.findIndex((v) => v._id === editedItem.value._id);
  const delResponse = await characterStore.deleteCharacterObject(editedItem.value._id);
  if (delResponse.success) {
    items.value.splice(index, 1);
  }
};

const ensurePopulatedObjectDefinition = async (doc) => {
  if (!doc.objectDefinition?._id) {
    const objResponse = await systemStore.getObjectDefinition(doc.objectDefinition);
    if (objResponse.success) {
      // doc.objectDefinition = await systemStore.getObjectDefinition(doc.objectDefinition);
      const objectDefinitionResult = await systemStore.getObjectDefinition(doc.objectDefinition);
      if (objectDefinitionResult.success) {
        doc.objectDefinition = objectDefinitionResult.data;
      }
    }
  }
};

const save = async (item) => {
  loading.value = true;
  if (item._id) {
    const updateResponse = await characterStore.updateCharacterObject(item);
    if (updateResponse.success) {
      const index = items.value.findIndex((v) => v._id === editedItem.value._id);
      Object.assign(items.value[index], item);
    }
  } else {
    const addResponse = await characterStore.addCharacterObject(item);
    if (addResponse.success) {
      const savedDoc = addResponse.data;
      await ensurePopulatedObjectDefinition(savedDoc);
      items.value.push(savedDoc);
    }
  }
  loading.value = false;
  closeObjectDialog();
  return true;
};

const onSearchUpdate = (val) => {
  search.value = val;
};

const filteredItems = computed(() => {
  let tempItems = items.value;

  if (search.value) {
    const searchVal = search.value.toLowerCase();
    tempItems = tempItems.filter((item) => item.label.toLowerCase().includes(searchVal));
  }
  return tempItems;
});

onMounted(async () => {
  let thisList;
  if (character.value.characterLists) {
    const allLists = character.value.characterLists || [];
    thisList = allLists.find((l) => l.listDefinition === myProps.listDef._id || l.listDefinition?._id === myProps.listDef._id);
  }
  if (!thisList) {
    const initResponse = await characterStore.initEmptyCharacterList(character.value._id, myProps.listDef._id);
    if (initResponse.success) {
      thisList = initResponse.data;
    }
  }

  items.value = thisList?.items || [];
  characterListID.value = thisList._id;
  defaultItem.characterList = thisList._id;

  // We do this because the listDef.allowedObjectDefinitions isn't populated, otherwise.
  const listDefResponse = await systemStore.getListDefinition(myProps.listDef._id);
  if (listDefResponse.success) {
    localListDef.value = listDefResponse.data;
  }
});
</script>

<template>
  <div>
    <list-container @search-update="onSearchUpdate">
      <template #button>
        <v-menu>
          <template #activator="{ props }">
            <v-btn v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" class="float-right" variant="flat" color="primary" v-bind="props">
              Add ...
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(objDef, index) in localListDef.allowedObjectDefinitions"
              :key="index"
              :value="objDef"
              @click="openAddObjectDialog(objDef)"
            >
              <v-list-item-title>{{ objDef.objectName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-data-table
        items-per-page="-1"
        :headers="headers"
        :items="filteredItems"
        item-value="_id"
        class="elevation-1"
        :no-data-text="`No ${localListDef.listName}`"
        @click:row="onRowClick"
      >
        <template #[`item.actions`]="{ item }">
          <div class="text-no-wrap">
            <delete-icon v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" @click.stop="openDeleteDialog(item)" />
          </div>
        </template>

        <template #[`item.label`]="{ item }">
          <span class="font-weight-bold">{{ item.label }} ({{ item.objectDefinition.objectName }})</span>
          <modded-icon v-if="item.modifications?.length || item.effects?.length" class="ml-2" />

          <div v-if="item.objectDefinition.fields">
            <span v-for="field in item.objectDefinition.fields.filter((f) => f.prominent || f.type.toLowerCase() === 'variable')" :key="field.name" class="prominent-field">
              <character-object-field-with-calculations :field-name="field.name" :field-description="field.description" :character-object="item" :field-label="field.label" />
            </span>
          </div>
        </template>

        <template #bottom></template>
      </v-data-table>
    </list-container>

    <object-edit-dialog v-model="dialog" :edited-entity="editedItem" :save-function="save" />

    <delete-dialog v-model="dialogDelete" :delete-function="deleteItem" :label="editedItem.label" />
  </div>
</template>

<style scoped>
.prominent-field {
  display: block;
  clear: both;
  font-size: 0.7rem;
}
</style>
