<script setup>
import { inject } from 'vue';
import { getXPCostOfAttribute } from '@/utils/xp-math';

const character = inject('printCharacter');
</script>

<template>
  <div class="special-attributes-section">
    <div class="special-attribute-table">
      <div class="title">
        Quirks, Advantages, and Disadvantages
      </div>
      <div class="list">
        <div v-for="attrib in character.specialAttributes" :key="attrib._id" class="special-attribute-row">
          <div class="name-column">
            <span>{{ attrib.specialAttribute.attributeName }}</span>
            <span class="xp-cost">(XP: {{ getXPCostOfAttribute(attrib.specialAttribute.xpCosts, attrib.rank) }})</span>
          </div>
          <div class="rank-column">
            {{ attrib.rank }}
          </div>
          <div class="other-column">
            <!--        <span class="rank">Rank: {{ attrib.rank }}</span>-->
            <div class="qualifier">
              {{ attrib.qualifier }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.special-attributes-section {
  margin-top: 1px;
  display: inline-block;
  height: 100%;
  width: 100%;
  border: 1px solid lightgrey;
}

.special-attribute-table {
  display: inline-block;
  width: 100%;
}

.special-attribute-row {
  vertical-align: top;
  padding-left: 0.05in;
  padding-top: 0.05in;
  padding-bottom: 0.07in;
  border-bottom: 1px solid lightgrey;
}

.title {
  background-color: black;
  color: white;
  text-align: center;
}

.name-column {
  display: inline-block;
  font-weight: bold;
  font-size: 0.8rem;
  width: 1.5in;
  vertical-align: top;
}

.rank-column {
  display: inline-block;
  font-weight: bold;
  font-size: 0.8rem;
  width: .2in;
  text-align: center;
  vertical-align: top;
}

.other-column {
  display: inline-block;
  width: 1.1in;
  white-space: normal;
  vertical-align: top;
  overflow: hidden;
}

.qualifier {
}

.xp-cost {
  font-weight: normal;
  font-size: 0.7rem;
}
</style>
