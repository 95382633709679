import {
  createAction,
  updateAction,
  deleteAction,
} from '@/store/_helpers/server-action-helpers';

const modificationDefinitionActions = {
  // eslint-disable-next-line require-await
  async addModificationDefinition(modDefinition, errorHandler) {
    const url = '/api/moddefs';
    return createAction(url, modDefinition, 'modification definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateModificationDefinition(modDefinition, errorHandler) {
    const url = `/api/moddefs/${modDefinition._id}`;
    return updateAction(url, modDefinition, 'modification definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteModificationDefinition(modDefID, errorHandler) {
    const url = `/api/moddefs/${modDefID}`;
    return deleteAction(url, 'modification definition', modDefID, false, errorHandler);
  },
};

export default modificationDefinitionActions;
