<script setup>
import {
  ref,
  readonly,
  onMounted,
  provide,
  computed,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useCharacterStore from '@/store/character/character-store';
import useSystemStore from '@/store/system/system-store';
import useOfflineStore from '@/store/offline-store';

import SpecialAttributesSection from '@/views/character-view/sections/special-attributes-section/SpecialAttributesSection.vue';
import CharacterSettingsSection from '@/views/character-view/sections/CharacterSettingsSection.vue';
import FormNumber from '@/components/form/FormNumber.vue';
import PowerGridSection
  from '@/views/character-view/sections/power-grid-section/PowerGridSection.vue';
import ChameleonField from '@/components/ChameleonField.vue';
import { getDefaultValueBasedOnFieldDef } from '@/utils/get-default-value-based-on-field-def';
import CharacterListSection from './sections/character-list-section/CharacterListSection.vue';

const characterStore = useCharacterStore();
const systemStore = useSystemStore();
const offlineStore = useOfflineStore();

const route = useRoute();
const router = useRouter();

const tabName = ref('powers');
const loading = ref(true);
const system = ref({});
const character = ref({});
const dirty = ref(false);

const makeDirty = () => {
  dirty.value = true;
};

provide('character', character);
provide('characterSystem', system);
provide('characterDirty', readonly(dirty));
provide('makeCharacterDirty', makeDirty);

const saveCharacter = async () => {
  loading.value = true;
  const updateResponse = await characterStore.updateCharacter(character.value);
  dirty.value = !updateResponse.success;
  loading.value = false;
};

const ensureAllFieldsExist = () => {
  if (character.value.customFields === undefined) {
    character.value.customFields = {};
  }
  if (system.value.characterFieldDefinitions) {
    system.value.characterFieldDefinitions.forEach((fieldDef) => {
      if (character.value.customFields[fieldDef.name] === undefined) {
        const defaultValue = getDefaultValueBasedOnFieldDef(fieldDef.type);
        character.value.customFields[fieldDef.name] = defaultValue;
      }
    });
  }
};

const updateCustomField = (fieldName, val) => {
  character.value.customFields[fieldName] = val;
  dirty.value = true;
};

const tabs = computed(() => {
  const retval = [];

  retval.push({
    value: 'powers',
    label: 'Powers',
  });

  if (system.value.specialAttributes) {
    retval.push({
      value: 'special',
      label: system.value?.specialAttributesSettings?.label || 'Special',
    });
  }

  if (Array.isArray(system.value.listDefinitions)) {
    system.value.listDefinitions.forEach((listDef) => {
      retval.push({
        value: listDef.listName,
        label: listDef.listName,
      });
    });
  }

  retval.push({
    value: 'settings',
    label: 'Settings',
  });

  return retval;
});

// noinspection FunctionWithMultipleLoopsJS
onMounted(async () => {
  const charResponse = await characterStore.getCharacter(route.params.id);
  if (charResponse.success) {
    character.value = charResponse.data;

    const sysResponse = await systemStore.getSystem(character.value.system);
    if (sysResponse.success) {
      system.value = sysResponse.data;
    }

    tabName.value = route.query.tab || 'powers';
  }

  ensureAllFieldsExist();

  loading.value = false;
});

const onTabChange = () => {
  router.replace({ query: { ...router.currentRoute.value.query, tab: tabName.value } });
};

watch(tabName, () => {
  onTabChange();
});
</script>

<template>
  <div>
    <v-progress-circular v-if="!character?._id" indeterminate />
    <v-card v-else>
      <v-card-title>
        <v-container class="pa-0 ma-0">
          <v-row dense>
            <v-col cols="10" xs="9">
              <span class="char-name">{{ character.characterName }}</span>
              <span class="quester-name">{{ character.quester.fullName }}</span>
            </v-col>
            <v-col cols="2" xs="3">
              <v-btn v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" class="bg-primary float-right" :disabled="!dirty" :loading="loading" @click.stop="saveCharacter">
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="9">
              <div class="xp-frame">
                <div class="xp-frame-label">
                  XP
                </div>
                <div class="xp-subframe xp-total">
                  <div class="xp-subframe-label">
                    Spent
                  </div>
                  {{ characterStore.xpSpent }}
                </div>
                <div class="xp-subframe xp-available" :class="{negative: character.xpAvailable < 0}">
                  <div class="xp-subframe-label">
                    Available
                  </div>
                  <span v-if="offlineStore.offlineMode">{{ character.xpAvailable }}</span>
                  <form-number v-else v-model="character.xpAvailable" label="" variant="solo" class="pa-0 ma-0" density="compact" @update:model-value="dirty = true" />
                </div>
              </div>
            </v-col>
            <v-col v-if="system?.walletSettings?.use" cols="12" sm="3">
              <div class="wallet-frame">
                <div class="wallet-frame-label">
                  {{ system.walletSettings.label }}
                </div>
                <div class="wallet-subframe wallet-available" :class="{negative: character.moneyAvailable < 0}">
                  <div class="wallet-subframe-label">
                    Available
                  </div>
                  <span v-if="offlineStore.offlineMode">{{ character.moneyAvailable }}</span>
                  <form-number v-else v-model="character.moneyAvailable" label="" variant="solo" class="pa-0 ma-0" density="compact" @update:model-value="dirty = true" />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-text class="px-0">
        <v-container>
          <v-row v-if="system?.characterFieldDefinitions?.length">
            <v-col v-for="(fieldDef, index) in system.characterFieldDefinitions.sort((a, b) => a.sortOrder - b.sortOrder)" :key="index" cols="auto" density="compact" style="min-width: 200px;">
              <chameleon-field v-vis-perms="'Character.UPDATE'" :disabled="offlineStore.offlineMode" :model-value="character.customFields[fieldDef.name]" :field-def="fieldDef" @update:modelValue="(val) => updateCustomField(fieldDef.name, val)" />
            </v-col>
          </v-row>
        </v-container>
        <v-tabs v-model="tabName" show-arrows @update:modelValue="onTabChange">
          <v-tab v-for="tabDef in tabs" :key="tabDef.value" :value="tabDef.value" xclass="hidden-xs">
            {{ tabDef.label }}
          </v-tab>
        </v-tabs>
        <v-window v-model="tabName">
          <v-window-item value="powers">
            <power-grid-section />
          </v-window-item>
          <v-window-item value="special">
            <special-attributes-section />
          </v-window-item>
          <v-window-item v-for="listDef in system.listDefinitions" :key="listDef._id" :value="listDef.listName">
            <character-list-section :list-def="listDef" />
          </v-window-item>
          <v-window-item value="settings">
            <character-settings-section />
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.char-name {
  font-weight: bold;
  font-size: 2rem;
  display: block;
}

.quester-name {
  margin-left: 10px;
  font-size: 1rem;
  display: block;
}

.xp-frame, .wallet-frame {
  border: 2px solid grey;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  margin-top: 20px;
}

.xp-frame {
  width: 200px;
}

.no-padding-i-mean-it {
  padding: 0 !important;
}

.wallet-frame {
  width: 150px;
}

.xp-subframe, .wallet-subframe {
  margin-top: 10px;
  box-sizing: border-box;
  border: 2px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.xp-subframe {
  width: 50%;
}

.wallet-subframe {
  width: 100%;
}

.xp-frame .xp-subframe:not(:last-child) {
  margin-right: 10px;
}

.xp-total {
  text-align: center;
  font-size: 1.5rem;
}

.xp-frame-label, .wallet-frame-label {
  font-size: 1rem;
  color: gray;
  top: -25px;
  left: 10px;
  padding: 0 5px;
  position: absolute;
}

.xp-subframe-label, .wallet-subframe-label {
  font-size: 0.6rem;
  color: gray;
  top: -25px;
  left: 10px;
  padding: 0 5px;
  position: absolute;
}

.xp-subframe input {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.wallet-subframe input {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.negative input {
  color: red;
}
</style>
