import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const attributeActions = {
  // eslint-disable-next-line require-await
  async addAttribute(systemID, pathID, tierID, attribute, errorHandler) {
    const url = `/api/attributes/${systemID}/${pathID}/${tierID}`;
    return createAction(url, attribute, 'attribute', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateAttribute(attribute, errorHandler) {
    const url = `/api/attributes/${attribute._id}`;
    return updateAction(url, attribute, 'attribute', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteAttribute(systemID, pathID, tierID, attributeID, errorHandler) {
    const url = `/api/attributes/${systemID}/${pathID}/${tierID}/${attributeID}`;
    return deleteAction(url, 'attribute', attributeID, false, errorHandler);
  },
};

export default attributeActions;
