import {
  getOneAction,
  updateAction,
} from '@/store/_helpers/server-action-helpers';

import { defineStore } from 'pinia';

const defaultPrefs = {
  darkMode: false,
  currentSystemID: null,
  currentEventID: null,
  currentEventGroupID: null,
};

const hasPermissionMatch = (permissions, availableTo, superAdmin) => {
  const lowerCasePermissions = (permissions ?? []).map((p) => p.toLowerCase());
  const lowerCaseAvailableTo = (availableTo ?? []).map((a) => a.toLowerCase());

  let match = !!superAdmin;

  if (lowerCaseAvailableTo.length === 0) {
    match = true;
  }

  lowerCaseAvailableTo.forEach((available) => {
    if (lowerCasePermissions.includes(available)) {
      match = true;
    }
  });

  return match;
};

const useMeStore = defineStore('me', {
  state: () => ({
    user: {},
  }),
  getters: {
    darkMode: (state) => !!state?.prefs?.darkMode,
    currentSystemID: (state) => state?.user?.prefs?.currentSystemID || null,
    currentEventID: (state) => state?.user?.prefs?.currentEventID || null,
    currentEventGroupID: (state) => state?.user?.prefs?.currentEventGroupID || null,
    isAuthenticated: (state) => !!state?.user?._id,
    prefs: (state) => state?.user?.prefs ?? defaultPrefs,
    permissions: (state) => state?.user?.simplePermissions || [],
  },
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    setUser(userData) {
      this.user = userData;
    },
    clearUser() {
      this.user = {};
    },
    hasPermissions(permissions) {
      let requestedPermissions = [];
      if (Array.isArray(permissions)) {
        requestedPermissions = permissions;
      }
      if (typeof permissions === 'string') {
        requestedPermissions = [permissions];
      }
      return hasPermissionMatch(this.permissions, requestedPermissions, this.user.superAdmin);
    },
    async savePrefs(errorHandler) {
      const prefs = {
        currentSystemID: this?.user?.prefs?.currentSystemID || null,
        currentEventID: this?.user?.prefs?.currentEventID || null,
        currentEventGroupID: this?.user?.prefs?.currentEventGroupID || null,
        darkMode: !!this?.user?.prefs?.darkMode,
      };
      await updateAction(`/api/users/${this?.user?._id}/prefs`, prefs, 'preferences', false, errorHandler);
    },
    async loadMe(errorHandler) {
      const response = await getOneAction('/auth/me', 'user', null, false, errorHandler);
      if (response.success) {
        this.setUser(response.data);
      }
    },
    async clearFilters() {
      this.user.prefs.currentSystemID = null;
      this.user.prefs.currentEventID = null;
      this.user.prefs.currentEventGroupID = null;
      await this.savePrefs();
    },
  },
});

export default useMeStore;
