import processAPIPromise from '@/store/_helpers/processAPIPromise';
import actions from '@/store/_helpers/actions';

// eslint-disable-next-line require-await
export const createAction = async (url, thingToCreate, entityType, offlineable, errorHandler) => {
  const options = {
    action: actions.CREATE,
    entity: entityType,
    errorHandler,
    offlineable,
    data: thingToCreate,
    defaultData: {},
  };

  return processAPIPromise(url, 'POST', options);
};

// eslint-disable-next-line require-await
export const updateAction = async (url, thingToUpdate, entityType, offlineable, errorHandler) => {
  const options = {
    action: actions.UPDATE,
    entity: entityType,
    errorHandler,
    offlineable,
    data: thingToUpdate,
    defaultData: {},
  };
  if (thingToUpdate.id || thingToUpdate._id) {
    options.id = thingToUpdate.id || thingToUpdate._id;
  }
  return processAPIPromise(url, 'PUT', options);
};

// eslint-disable-next-line require-await
export const deleteAction = async (url, entityType, id, offlineable, errorHandler) => {
  const options = {
    action: actions.DELETE,
    entity: entityType,
    id,
    errorHandler,
    offlineable,
    defaultData: null,
  };
  return processAPIPromise(url, 'DELETE', options);
};

// eslint-disable-next-line require-await
export const getManyAction = async (url, entityType, offlineable, errorHandler) => {
  const options = {
    action: actions.GETALL,
    entity: entityType,
    errorHandler,
    offlineable,
    defaultData: [],
  };
  return processAPIPromise(url, 'GET', options);
};

// eslint-disable-next-line require-await
export const getOneAction = async (url, entityType, id, offlineable, errorHandler) => {
  const options = {
    action: actions.GET,
    entity: entityType,
    id,
    errorHandler,
    offlineable,
    defaultData: {},
  };
  return processAPIPromise(url, 'GET', options);
};
