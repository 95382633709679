<script setup>
import {
  ref,
  computed,
  watch,
  inject,
} from 'vue';
import FormRating from '@/components/form/FormRating.vue';
import FormText from '@/components/form/FormText.vue';
import FormTextarea from '@/components/form/FormTextarea.vue';
import useOfflineStore from '@/store/offline-store';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  saveFunction: {
    type: Function,
    required: true,
  },
  editedEntity: {
    type: Object,
    required: true,
  },
  attributes: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue', 'updated', 'added']);

const offlineStore = useOfflineStore();
const loading = ref(false);
const entity = ref(props.editedEntity);

const system = inject('characterSystem');

const itemLabel = computed(() => system.value.specialAttributesSettings?.itemLabel || 'Attribute');

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

watch(() => props.editedEntity, (newData) => {
  entity.value = { ...newData };
});

const close = () => {
  dialog.value = false;
};

const saveEntity = async () => {
  loading.value = true;
  const saved = await props.saveFunction(entity.value);
  if (saved) {
    if (entity.value._id) {
      emit('added', entity.value);
    } else {
      emit('updated', entity.value);
    }
  }
  close();
  loading.value = false;
};
</script>

<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="px-1 px-sm-4">
        <v-card-title>
          {{ entity._id ? `Edit ${entity.specialAttribute.attributeName} ${itemLabel}` : `Add ${itemLabel}` }}
        </v-card-title>
        <v-card-text class="px-1 px-sm-4">
          <v-container class="px-1">
            <v-col cols="12" class="px-1 px-sm-4">
              <v-row>
                <v-col v-if="!entity._id" cols="12" class="px-1 px-sm-4">
                  <v-autocomplete
                    v-model="entity.specialAttribute"
                    v-vis-perms="'Character.UPDATE'"
                    :readonly="offlineStore.offlineMode"
                    label="Quirk"
                    :items="attributes"
                    auto-select-first
                    item-title="attributeName"
                    item-value="_id"
                    return-object
                    hide-details
                    clearable
                  />
                </v-col>
                <v-col v-if="entity?.specialAttribute?.numberOfLevels > 1" cols="12" class="px-1 px-sm-4">
                  <form-rating v-model="entity.rank" v-vis-perms="'Character.UPDATE'" :disabled="offlineStore.offlineMode" label="Rank" :max-value="entity.specialAttribute.numberOfLevels" info="If it applies to this attribute, you can indicate the character's rank/level with this attribute. This is meant to be descriptive and has no binding effect in the system." />
                </v-col>
                <v-col cols="12" class="px-1 px-sm-4">
                  <form-text v-model="entity.qualifier" v-vis-perms="'Character.UPDATE'" :readonly="offlineStore.offlineMode" label="Qualifier" info="A little optional information that modifies this character's attribute, e.g. 'extra smelly', 'with rainbow glitter', 'etc'" />
                </v-col>
                <v-col cols="12" class="px-1 px-sm-4">
                  <form-textarea v-model="entity.notes" v-vis-perms="'Character.UPDATE'" :readonly="offlineStore.offlineMode" label="Notes" info="Keep whatever notes you want about this character's attribute.  Try to keep it fairly brief. These might be displayed to the player." />
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-darken-1" variant="text" @click="close">
            Cancel
          </v-btn>
          <v-btn v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" color="blue-darken-1" variant="text" @click="saveEntity">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
