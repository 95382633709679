import Cookies from 'js-cookie';

/**
 * Extracts the named cookie
 * @param {string} cookieName - The name of the cookie we are looking for
 * @returns {any|null} - The cookie, if found, else null
 */
export const getCookie = (cookieName) => Cookies.get(cookieName);

/**
 * Deletes the named cookie
 * @param {string} cookieName - The name of the cookie to delete
 */
export const deleteCookie = (cookieName) => {
  // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  Cookies.remove(cookieName);
};

export const setCookie = (name, val) => {
  // const encodedValue = json ? JSON.stringify(val) : encodeURIComponent(val);
  // const encodedName = encodeURIComponent(name);
  // document.cookie = `${encodedName}=${encodedValue}; path=/;`;
  Cookies.set(name, val, { sameSite: 'strict' });
};
