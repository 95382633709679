<script setup>
import { computed } from 'vue';
import InfoButton from '@/components/InfoButton.vue';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  info: {
    type: String,
    default: '',
  },
  maxValue: {
    type: Number,
    default: 5,
  },
});

const emit = defineEmits(['update:modelValue']);

const update = (event) => {
  const { value } = event.target;
  emit('update:modelValue', value);
};

const fieldValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

</script>

<template>
  <v-row class="align-center">
    <v-col class="v-col-auto my-0 mr-4 pa-0">
      {{ label }}:
    </v-col>
    <v-col class="v-col-auto ma-0 pa-0">
      <v-rating v-model="fieldValue" v-bind="$attrs" :length="Number.isInteger(maxValue) ? maxValue : 5" clearable @input="update">
      </v-rating>
    </v-col>
    <v-col class="v-col-auto my-0 ml-4 pa-0">
      <info-button :message="info" />
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
