<script setup>
import { inject, ref, watch } from 'vue';
import PrintablePowerGridAttribute from '@/views/character-view-printable/sections/PrintablePowerGridAttribute.vue';

const system = inject('printSystem');
const character = inject('printCharacter');

const tiers = ref([]);

const getExtendedTiers = (path) => path.tiers.filter((t) => t.extended);

const getCharacterAttributeRank = (attributeID, defaultValue = 0) => {
  const characterAttributes = character.characterAttributes || [];
  const attrib = characterAttributes.find((a) => a.systemAttribute === attributeID);
  return attrib ? attrib.rank : defaultValue;
};

const hasRankedAttribute = (tier) => {
  const systemIds = new Set(tier.attributes.map((attr) => attr._id));
  return character.characterAttributes.some((attr) => systemIds.has(attr.systemAttribute));
};

// noinspection FunctionWithMultipleLoopsJS
const getFlattenedExtendedTiers = () => {
  const localTiers = [];
  system.paths.forEach((path) => {
    const extendedTiers = getExtendedTiers(path);
    const rankedTiers = extendedTiers.filter(hasRankedAttribute);
    if (rankedTiers.length) {
      localTiers.push(...rankedTiers);
    }
  });

  localTiers.forEach((tier) => {
    tier.attributes.forEach((attrib) => {
      attrib.rank = getCharacterAttributeRank(attrib._id);
    });
  });

  return localTiers;
};

watch(character, (newValue) => {
  if (!newValue.loading) {
    tiers.value = getFlattenedExtendedTiers();
  }
}, { deep: true });
</script>

<template>
  <div xclass="power-grid" style="margin-top: 0.05in;">
    <table v-if="tiers.length" class="power-grid">
      <tr>
        <td class="first-column">
          Extended
        </td>
        <td v-for="tier in tiers" :key="tier._id" class="tier-box">
          <table style="width: 101.5%;margin-left: -0.5px;margin-right: 0.5px;">
            <tr style="vertical-align: top;">
              <td class="extended-tier-header" colspan="2">
                {{ tier.tierName }}
              </td>
            </tr>
            <tr v-for="attrib in tier.attributes" :key="attrib._id">
              <td class="attribute-label">
                {{ attrib.attributeName }}
              </td>
              <td class="attribute-rank">
                <printable-power-grid-attribute :system-attribute="attrib" :number-of-ranks="5" />
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <span v-else>Extended Powers or Notes</span>
  </div>
</template>

<style scoped>
.power-grid {
  border: 1px solid lightgrey;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  width: 100%;
}
.first-column {
  /*width: 0.2in;*/
  max-width: 0.2in;
  background-color: black;
  color: white;
  writing-mode: vertical-lr;
  text-align: center;
  transform: rotate(180deg);
  width: 30px;
  font-weight: bold;
}
.extended-tier-header {
  text-align: center;
  padding-top: 2px;
  background-color: black;
  color: white;
  height: .2in;
}
.tier-box {
  display: inline-block;
  height: 1in;
  min-height: 1in;
  width: 33.3%;
  border: 1px solid lightgrey;
}
.attribute-label {
  width: 0.8in;
  max-width: 0.8in;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* border: 1px solid red; */
}

.attribute-rank {
}
</style>
