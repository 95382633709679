<script setup>
import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import StarQuestCharacterViewPrintableComponent from '@/views/character-view-printable/systems/StarQuestCharacterViewPrintableComponent.vue';

import useCharacterStore from '@/store/character/character-store';
import useSystemStore from '@/store/system/system-store';

const characterStore = useCharacterStore();
const systemStore = useSystemStore();
const route = useRoute();

const loading = ref(true);
const system = ref({ loading: true });
const character = ref({ loading: true });

onBeforeMount(async () => {
  const charResponse = await characterStore.getCharacter(route.params.id);
  if (charResponse.success) {
    character.value = charResponse.data;

    const sysResponse = await systemStore.getSystem(character.value.system);
    if (sysResponse.success) {
      system.value = sysResponse.data;
    }
  }

  loading.value = false;
});
</script>

<template>
  <v-progress-circular v-if="loading" indeterminate />
  <star-quest-character-view-printable-component v-else :character="character" :system="system" />
</template>

<style lang="scss">

</style>
