import { createAction } from '@/store/_helpers/server-action-helpers';

const characterListActions = {

  /**
   * Adds a character list to the DB.
   * @param {object} charList - The character list to add.
   * @param {Function} errorHandler - The error handler function to handle any errors that occur.
   * @returns {Promise} - A promise that resolves when the character list is added successfully, or rejects with an error.
   */
  // eslint-disable-next-line require-await
  async addCharacterList(charList, errorHandler) {
    const url = '/api/charlists';
    return createAction(url, charList, 'character list', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async initEmptyCharacterList(characterID, listDefinitionID, errorHandler) {
    return this.addCharacterList({
      character: characterID,
      listDefinition: listDefinitionID,
      items: [],
    }, errorHandler);
  },
};

export default characterListActions;
