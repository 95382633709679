<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue']);

const fieldValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const update = (event) => {
  const { value } = event.target;
  emit('update:modelValue', value);
};
</script>

<template>
  <v-text-field
    v-model="fieldValue"
    append-inner-icon="mdi-magnify"
    label="Search"
    single-line
    hide-details
    variant="solo"
    clearable
    class="mr-2"
    @input="update"
    @click:clear="emit('update:modelValue', $event.target.value)"
  />
</template>

<style scoped>

</style>
