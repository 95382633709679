<template>
  <v-app id="inspire">
    <router-view />
    <v-dialog v-model="errorStore.hasError" max-width="500px">
      <v-card>
        <v-card-title class="bg-error">
          Woops
        </v-card-title>
        <v-card-text>
          <v-alert type="warning">
            An error has occurred. This is expected at this stage of development and is intentional. Try refreshing the page and trying again.
          </v-alert>
          <p class="mt-6">
            {{ errorStore.errorMessage }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="flat" text="OK" @click="errorStore.clearError()" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script setup>
import useAppStore from '@/store/app-store';
import useErrorStore from '@/store/errors-store';
import useMeStore from '@/store/users/me-store';
import { useTheme } from 'vuetify';
import { watch, onMounted, onBeforeUnmount } from 'vue';

const appStore = useAppStore();
const errorStore = useErrorStore();
const meStore = useMeStore();
const theme = useTheme();

watch(() => meStore.darkMode, (isDark) => {
  theme.global.name.value = isDark ? 'dark' : 'light';
});

const handleResize = () => {
  if (window.innerWidth < 800) {
    appStore.hideDrawer();
  }
};

onMounted(() => {
  theme.global.name.value = meStore.darkMode ? 'dark' : 'light';

  handleResize(); // Check immediately on mount
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style>

</style>
