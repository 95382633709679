<script setup>
import { ref } from 'vue';
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

defineProps({
  message: {
    type: String,
    required: true,
  },
  parseMarkdown: {
    type: Boolean,
    default: false,
  },
});

const dialog = ref(false);
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template #activator="{ props }">
      <v-icon v-bind="{ ...props, ...$attrs }" size="small" color="blue">
        mdi-information-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-text>
        <md-preview v-if="parseMarkdown" :content="message" />
        <div v-else>
          {{ message }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
