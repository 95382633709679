<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: 'this item',
  },
  deleteFunction: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue', 'deleted']);

const loading = ref(false);

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const close = () => {
  dialog.value = false;
};

const deleteItemConfirm = async () => {
  loading.value = true;
  const deleted = await props.deleteFunction();
  if (deleted) {
    emit('deleted');
  }
  close();
  loading.value = false;
};
</script>

<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card :loading="loading" class="pt-6 pb-2 bg-amber-lighten-2">
        <v-card-title class="text-wrap text-center">
          Are you sure you want to delete <br /><b>{{ label }}</b>?
        </v-card-title>
        <v-card-actions class="mx-auto">
          <v-btn color="blue-darken-1" variant="text" @click="close">
            Cancel
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
