import { defineStore } from 'pinia';
import {
  createAction,
  updateAction,
  deleteAction,
  getManyAction,
  getOneAction,
} from '@/store/_helpers/server-action-helpers';
import characterListActions from '@/store/character/actions/character-list-actions';
import characterObjectActions from '@/store/character/actions/character-object-actions';
// import characterFieldActions from '@/store/character/actions/character-field-actions';
import characterSpecialAttributeActions from '@/store/character/actions/character-special-attribute-actions';
import { calculateXPCostForCharacter } from '@/utils/xp-math';

const useCharacterStore = defineStore('characters', {
  state: () => ({
    xpSpent: 0,
  }),
  getters: {},
  actions: {
    spendXP(character, system, xp) {
      if (!Number.isNaN(xp)) {
        character.xpAvailable -= xp;
      }
      this.updateXPSpent(character, system);
    },
    updateXPSpent(character, system) {
      this.xpSpent = calculateXPCostForCharacter(character, system);
    },
    // eslint-disable-next-line require-await
    async getCharacters(errorHandler) {
      return getManyAction('/api/characters', 'character', true, errorHandler);
    },
    // eslint-disable-next-line require-await
    async getCharacter(id, errorHandler) {
      const url = `/api/characters/${id}`;
      return getOneAction(url, 'character', id, true, errorHandler);
      // const characterResult = await getOneAction(url, 'character', id, true, errorHandler);
      // if (characterResult) {
      //   const character = characterResult.data;
      //   if (character) {
      //     character.xpAvailable = Number.parseInt(character.xpAvailable, 10) || 0;
      //   }
      //   characterResult.data = character;
      // }
      // return characterResult;
    },
    // eslint-disable-next-line require-await
    async createCharacter(character, errorHandler) {
      const url = '/api/characters';
      return createAction(url, character, 'character', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async updateCharacter(character, errorHandler) {
      const url = `/api/characters/${character._id}`;
      return updateAction(url, character, 'character', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async deleteCharacter(characterID, errorHandler) {
      const url = `/api/characters/${characterID}`;
      return deleteAction(url, 'character', characterID, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async cloneCharacter(character, errorHandler) {
      const url = '/api/characters';
      delete character._id;
      delete character.id;
      delete character.createdAt;
      delete character.updatedAt;
      delete character.__v;
      return createAction(url, character, 'character', false, errorHandler);
    },
    ...characterListActions,
    ...characterObjectActions,
    ...characterSpecialAttributeActions,
  },
});

export default useCharacterStore;
