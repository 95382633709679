import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const variableDefinitionActions = {
  // eslint-disable-next-line require-await
  async addVariableDefinition(variableDefinition, errorHandler) {
    const url = '/api/variables';
    return createAction(url, variableDefinition, 'variable definition', errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateVariableDefinition(variableDefinition, errorHandler) {
    const url = `/api/variables/${variableDefinition._id}`;
    return updateAction(url, variableDefinition, 'variable definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteVariableDefinition(variableID, errorHandler) {
    const url = `/api/variables/${variableID}`;
    return deleteAction(url, 'variable definition', variableID, false, errorHandler);
  },
};

export default variableDefinitionActions;
