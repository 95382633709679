<script setup>
import { computed } from 'vue';
import InfoButton from '@/components/InfoButton.vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  info: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const update = (event) => {
  const { value } = event.target;
  emit('update:modelValue', value);
};

const fieldValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <div>
    <v-textarea
      v-model="fieldValue"
      v-bind="$attrs"
      :label="label"
      :disabled="disabled"
      @input="update"
    >
      <template #append>
        <info-button :message="info" />
      </template>
    </v-textarea>
  </div>
</template>

<style scoped>

</style>
