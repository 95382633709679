import { defineStore } from 'pinia';
import {
  createAction,
  deleteAction,
  getManyAction,
  getOneAction,
  updateAction,
} from '@/store/_helpers/server-action-helpers';

const useQuesterStore = defineStore('questers', {
  state: () => ({}),
  getters: {},
  actions: {
    // eslint-disable-next-line require-await
    async getQuesters(errorHandler) {
      return getManyAction('/api/questers', 'questers', errorHandler);
    },
    // eslint-disable-next-line require-await
    async getQuester(id, errorHandler) {
      const url = `/api/questers/${id}`;
      return getOneAction(url, 'quester', id, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async getQuesterCharacters(id, errorHandler) {
      return getManyAction(`/api/questers/${id}/characters`, 'quester characters', errorHandler);
    },
    // eslint-disable-next-line require-await
    async deleteQuester(id, errorHandler) {
      const url = `/api/questers/${id}`;
      return deleteAction(url, 'quester', id, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async createQuester(quester, errorHandler) {
      const url = '/api/questers';
      return createAction(url, quester, 'quester', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async updateQuester(quester, errorHandler) {
      const url = `/api/questers/${quester._id}`;
      return updateAction(url, quester, 'quester', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async getEvents(errorHandler) {
      return getManyAction('/api/events', 'events', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async getEvent(id, errorHandler) {
      const url = `/api/events/${id}`;
      return getOneAction(url, 'event', id, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async deleteEvent(id, errorHandler) {
      const url = `/api/events/${id}`;
      return deleteAction(url, 'event', id, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async createEvent(event, errorHandler) {
      const url = '/api/events';
      return createAction(url, event, 'event', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async updateEvent(event, errorHandler) {
      const url = `/api/events/${event._id}`;
      return updateAction(url, event, 'event', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async getEventGroups(errorHandler) {
      return getManyAction('/api/eventgroups', 'event groups', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async getEventGroup(id, errorHandler) {
      const url = `/api/eventgroups/${id}`;
      return getOneAction(url, 'event group', id, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async deleteEventGroup(id, errorHandler) {
      const url = `/api/eventgroups/${id}`;
      return deleteAction(url, 'event group', id, false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async createEventGroup(group, errorHandler) {
      const url = '/api/eventgroups';
      return createAction(url, group, 'event group', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async updateEventGroup(group, errorHandler) {
      const url = `/api/eventgroups/${group._id}`;
      return updateAction(url, group, 'event group', false, errorHandler);
    },
  },
});

export default useQuesterStore;
