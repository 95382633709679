<script setup>
import { computed } from 'vue';
import InfoButton from '@/components/InfoButton.vue';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  info: {
    type: String,
    default: '',
  },
  minValue: {
    type: Number,
    default: -32768,
  },
  maxValue: {
    type: Number,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const update = (event) => {
  let { value } = event.target;
  value = value.replace(/\D-/g, '');
  let intValue = Number.parseFloat(value);
  if (props.minValue !== null && intValue < props.minValue) {
    intValue = props.minValue;
  }
  if (props.maxValue !== null && intValue > props.maxValue) {
    intValue = props.maxValue;
  }
  fieldValue.value = intValue;
  emit('update:modelValue', intValue);
};

const fieldValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    const intValue = Number.parseFloat(value);
    emit('update:modelValue', intValue);
  },
});
</script>

<template>
  <v-text-field v-model="fieldValue" v-bind="$attrs" :readonly="disabled" type="number" :label="label" @input="update">
    <template v-if="info" #append>
      <info-button :message="info" />
    </template>
  </v-text-field>
</template>

<style scoped>

</style>
