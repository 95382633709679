import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const characterFieldDefinitionActions = {
  // eslint-disable-next-line require-await
  async addCharacterFieldDefinition(characterFieldDefinition, errorHandler) {
    const url = '/api/charfields';
    return createAction(url, characterFieldDefinition, 'character field', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateCharacterFieldDefinition(characterFieldDefinition, errorHandler) {
    const url = `/api/charfields/${characterFieldDefinition._id}`;
    return updateAction(url, characterFieldDefinition, 'character field', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteCharacterFieldDefinition(characterFieldDefinitionID, errorHandler) {
    const url = `/api/charfields/${characterFieldDefinitionID}`;
    return deleteAction(url, 'character field', characterFieldDefinitionID, false, errorHandler);
  },
};

export default characterFieldDefinitionActions;
