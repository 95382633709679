<script setup>
import {
  inject,
  ref,
} from 'vue';

const myProps = defineProps({
  systemAttribute: {
    type: Object,
    required: true,
  },
  numberOfRanks: {
    type: Number,
    default: 5,
  },
});
const character = inject('printCharacter');

const getCharacterAttributeRank = (attributeID, defaultValue = 0) => {
  const characterAttributes = character.characterAttributes || [];
  const attrib = characterAttributes.find((a) => a.systemAttribute === attributeID);
  return attrib ? attrib.rank : defaultValue;
};

const attributeRank = ref(getCharacterAttributeRank(myProps.systemAttribute._id, myProps.systemAttribute.defaultValue));
</script>

<template>
  <div>
    <v-rating
      v-model="attributeRank"
      density="compact"
      :length="numberOfRanks"
      class="ma-0 pa-0"
      empty-icon="mdi-checkbox-blank-outline"
      full-icon="mdi-checkbox-marked"
      readonly
      size="x-small"
      color="black"
    />
  </div>
</template>

<style scoped>
.xp {
  width: 30px;
  text-align: right;
}
</style>
