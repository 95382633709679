import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const tierActions = {
  // eslint-disable-next-line require-await
  async addTier(systemID, pathID, tier, errorHandler) {
    const url = `/api/systems/${systemID}/paths/${pathID}/tiers`;
    return createAction(url, tier, 'tier', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateTier(systemID, pathID, tier, errorHandler) {
    const url = `/api/systems/${systemID}/paths/${pathID}/tiers`;
    return updateAction(url, tier, 'tier', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteTier(systemID, pathID, tierID, errorHandler) {
    const url = `/api/systems/${systemID}/paths/${pathID}/tiers/${tierID}`;
    return deleteAction(url, 'tier', tierID, false, errorHandler);
  },
};

export default tierActions;
