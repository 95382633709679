const flattenEffects = (charObject) => {
  const effects = [...charObject.effects];

  charObject.modifications.forEach((mod) => {
    effects.push(...mod.modification.effectDefinitions);
  });

  return effects;
};

const getVariableFields = (objectDefinition) => objectDefinition.fields.filter((f) => f.type.toLowerCase() === 'variable').map((f) => f.name);
const getCharVariables = (charObject, objectDefVariables) => {
  const retval = {};
  objectDefVariables.forEach((variable) => {
    if (Object.keys(charObject.fields).includes(variable)) {
      retval[variable] = Number.parseFloat(charObject.fields[variable]);
    }
  });
  return retval;
};

const getEffectsThatAffectSpecificVariable = (effects, variableName) => effects.filter((e) => e.affectedVariable === variableName);

// eslint-disable-next-line import/prefer-default-export
export const getCalculatedFieldValuesForObject = (charObject) => {
  const variableFieldNames = getVariableFields(charObject.objectDefinition); // only these can be calculated
  const charVariables = getCharVariables(charObject, variableFieldNames);

  const allEffects = flattenEffects(charObject);

  Object.keys(charVariables).forEach((varName) => {
    const relevantEffects = getEffectsThatAffectSpecificVariable(allEffects, varName);
    const baseVal = charVariables[varName];
    let calcVal = baseVal;

    relevantEffects.forEach((effect) => {
      if (effect.effectAddition) {
        calcVal += effect.effectAddition;
      }
      if (effect.effectMultiplier) {
        const multiplier = Math.max(effect.effectMultiplier - 1, 1);
        calcVal += (baseVal * multiplier);
      }
    });

    charVariables[varName] = calcVal;
  });

  return charVariables;
};
