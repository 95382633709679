import { defineStore } from 'pinia';

const useAppStore = defineStore('app', {
  state: () => ({
    navDrawerVisible: true,
  }),
  getters: {
    drawerIsVisible: (state) => state.navDrawerVisible,
  },
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    toggleDrawer() {
      this.navDrawerVisible = !this.navDrawerVisible;
    },
    showDrawer() {
      this.navDrawerVisible = true;
    },
    hideDrawer() {
      this.navDrawerVisible = false;
    },
  },
});

export default useAppStore;
