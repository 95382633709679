import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const specialAttributeActions = {
  // eslint-disable-next-line require-await
  async addSpecialAttribute(specialAttributes, errorHandler) {
    const url = '/api/specials';
    return createAction(url, specialAttributes, 'special attribute', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateSpecialAttributes(specialAttributes, errorHandler) {
    const url = `/api/specials/${specialAttributes._id}`;
    return updateAction(url, specialAttributes, 'special attribute', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteSpecialAttributes(specialAttributeID, errorHandler) {
    const url = `/api/specials/${specialAttributeID}`;
    return deleteAction(url, 'special attribute', specialAttributeID, false, errorHandler);
  },
};

export default specialAttributeActions;
