/**
 * Calculates the total XP cost of an attribute based on the given XP costs array and rank.
 * @param {Array} xpCosts - The array of XP costs for each rank of the attribute.
 * @param {string} rank - The character's rank in the attribute.
 * @returns {number} - The total XP cost of the attribute.
 */
// eslint-disable-next-line import/prefer-default-export
export const getXPCostOfAttribute = (xpCosts, rank) => {
  let cost = 0;
  const intRank = Number.parseFloat(rank);

  for (let i = 1; i <= intRank; i++) {
    cost += xpCosts[i - 1];
  }

  if (Number.isNaN(cost)) {
    cost = 0;
  }

  return cost;
};

// I really shouldn't rebuild this every time I want to do an update, but for now ...
// noinspection FunctionWithMultipleLoopsJS
const makeAttributeXPCostMap = (system) => system.paths.reduce((acc, path) => {
  path.tiers.forEach((tier) => {
    tier.attributes.forEach((attribute) => {
      acc[attribute._id] = tier.xpCosts;
    });
  });
  return acc;
}, {});

export const getXPCostsOfSystemAttributeByID = (system, systemAttribute) => {
  const systemAttributeID = systemAttribute?._id ? systemAttribute._id : systemAttribute;
  const xpAttributeXPCostMap = makeAttributeXPCostMap(system);
  return xpAttributeXPCostMap[systemAttributeID] ?? [];
};

// noinspection FunctionWithMultipleLoopsJS
export const calculateXPCostForCharacter = (character, system) => {
  let total = 0;

  character.characterAttributes.forEach((attrib) => {
    const xpCosts = getXPCostsOfSystemAttributeByID(system, attrib.systemAttribute);
    const cost = getXPCostOfAttribute(xpCosts, attrib.rank);
    total += cost;
  });

  character.specialAttributes.forEach((attrib) => {
    const cost = getXPCostOfAttribute(attrib.specialAttribute.xpCosts, attrib.rank);
    total += cost;
  });

  return total;
};
