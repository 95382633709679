<script setup>
import SearchBox from '@/components/SearchBox.vue';
import { ref, watch } from 'vue';

const search = ref('');
const emit = defineEmits(['searchUpdate']);

watch(search, () => {
  emit('searchUpdate', search.value);
});
</script>

<template>
  <v-container class="pa-0 ma-0 mb-8 fill-height align-content-start">
    <v-toolbar class="pa-2" color="secondary">
      <div class="flex-grow-1 mx-2">
        <search-box v-model="search" style="width: 100%;" />
      </div>
      <div class="flex-grow-1">
        <slot name="middle" />
      </div>
      <div class="flex-shrink-1 text-right mx-2">
        <slot name="button" />
      </div>
    </v-toolbar>
    <slot name="default" />
  </v-container>
</template>

<style scoped>

</style>
