import { defineStore } from 'pinia';
import {
  addObjects,
  addObject,
  clear,
  deleteDatabase,
} from '@/store/_helpers/offline-storage';
import processAPIPromise from '@/store/_helpers/processAPIPromise';
import { offlineMode, setOfflineCookie, removeOfflineCookie } from '@/utils/offline';

// We have this near-duplicate function here to prevent cyclic dependency
const getMany = (url, entityType) => {
  const options = {
    action: 'getting list',
    entity: entityType,
    offlineMode: false,
    defaultData: [],
  };
  // return processAPIPromise(axios.get(url), defaultData, options);
  return processAPIPromise(url, 'GET', options);
};

// eslint-disable-next-line require-await
const getOne = async (url, entityType) => {
  // const operation = `getting ${entityType}`;
  const options = {
    action: 'getting',
    entity: entityType,
    offlineMode: false,
    defaultData: {},
  };
  return processAPIPromise(url, 'GET', options);
};

const cacheCharacers = async (systemID) => {
  let success = true;
  // The getMany action doesn't get all of the information we need per Character.
  // So, we iterate through the collection and pull each complete record.
  const charsResponse = await getMany('/api/characters', 'characters');
  if (charsResponse.success) {
    const characters = charsResponse.data;
    const systemCharacters = characters.filter((c) => c.system === systemID);

    const charIDs = systemCharacters.map((c) => c._id);

    const fullCharList = [];
    await Promise.all(charIDs.map(async (id) => {
      const fullCharResponse = await getOne(`/api/characters/${id}`, 'character');
      if (fullCharResponse.success) {
        fullCharList.push(fullCharResponse.data);
      }
    }));
    await addObjects('characters', fullCharList);
  } else {
    success = false;
  }
  return success;
};

const cacheSystem = async (systemID) => {
  let success = true;
  const systemResponse = await getOne(`/api/systems/${systemID}`, 'system');
  if (systemResponse.success) {
    const system = systemResponse.data;
    await addObject('systems', system);
  } else {
    success = false;
  }
  return success;
};

const cacheUser = async (user) => {
  let retval = true;
  try {
    const userToAdd = JSON.parse(JSON.stringify(user)); // Don't know why we have to do this for user and not anything else.
    await addObject('users', userToAdd);
  } catch (err) {
    console.error(err);
    retval = false;
  }
  return retval;
};

const useOfflineStore = defineStore('offline', {
  state: () => ({
  }),
  getters: {
    offlineMode: () => offlineMode.value,
  },
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    async goOffline(systemID, user) {
      // @todo: Add the ability to pass in a list of characters or questers.  For now, we'll just grab them all.
      let success = true;

      await clear('characters');
      await clear('systems');
      await clear('users');

      success = success && await cacheCharacers(systemID);
      success = success && await cacheSystem(systemID);
      success = success && await cacheUser(user);

      if (success) {
        this.offline = true;
        setOfflineCookie();
      } else {
        this.offline = false;
        removeOfflineCookie();
      }
    },
    async goOnline() {
      removeOfflineCookie();
      this.offline = false;
      await deleteDatabase();
    },
  },
});

export default useOfflineStore;
