<script setup>
import { ref } from 'vue';
import useAuthStore from '@/store/auth-store';
import useMeStore from '@/store/users/me-store';
import router from '@/router/index';
import LoginDialog from '@/components/login/LoginDialog.vue';

const authStore = useAuthStore();
const meStore = useMeStore();

const menu = ref(false);
const loggingOut = ref(false);

const setDarkMode = () => {
  meStore.savePrefs();
};

const logout = () => {
  loggingOut.value = true;
  authStore.logout();
  router.push('/').then();
  loggingOut.value = false;
};
</script>

<template>
  <div>
    <login-dialog v-if="!authStore.isAuthenticated" />
    <div v-else>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        close-on-back
      >
        <template #activator="{ props }">
          <v-avatar color="info" v-bind="props">
            <v-icon icon="mdi-account-circle"></v-icon>
          </v-avatar>
        </template>

        <v-card min-width="300" :loading="loggingOut">
          <v-list>
            <v-list-item :title="authStore.name" subtitle="Staff" />
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-switch v-model="meStore.user.prefs.darkMode" color="primary" label="Dark Mode" hide-details @update:model-value="setDarkMode" />
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text="Logout" @click="logout" />
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<style scoped>

</style>
