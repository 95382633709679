import {
  createAction,
  updateAction,
  deleteAction,
  getOneAction,
} from '@/store/_helpers/server-action-helpers';

const listDefinitionActions = {
  // eslint-disable-next-line require-await
  async getListDefinition(listDefID, errorHandler) {
    const url = `/api/listdefs/${listDefID}`;
    return getOneAction(url, 'list definition', listDefID, false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async addListDefinition(listDefinition, errorHandler) {
    const url = '/api/listdefs';
    return createAction(url, listDefinition, 'list definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateListDefinition(listDefinition, errorHandler) {
    const url = `/api/listdefs/${listDefinition._id}`;
    return updateAction(url, listDefinition, 'list definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteListDefinition(listDefID, errorHandler) {
    const url = `/api/listdefs/${listDefID}`;
    return deleteAction(url, 'list definition', listDefID, false, errorHandler);
  },
};

export default listDefinitionActions;
