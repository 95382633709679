<script setup>
import {
  ref,
  inject,
  watch,
  computed,
} from 'vue';
import useCharacterStore from '@/store/character/character-store';
import useOfflineStore from '@/store/offline-store';
import DeleteDialog from '@/components/DeleteDialog.vue';
import { getXPCostOfAttribute } from '@/utils/xp-math';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import InfoButton from '@/components/InfoButton.vue';
import ListContainer from '@/components/ListContainer.vue';
import AttributeEditDialog from './AttributeEditDialog.vue';

const characterStore = useCharacterStore();
const offlineStore = useOfflineStore();

const character = inject('character');
const system = inject('characterSystem');

const itemsPerPage = ref(10);
const headers = ref([
  {
    title: 'Attribute',
    align: 'start',
    sortable: true,
    key: 'attributeName',
  }, {
    title: 'Qualifier',
    align: 'start',
    sortable: true,
    key: 'qualifier',
  }, {
    title: 'Actions',
    align: 'end',
    key: 'actions',
    sortable: false,
  },
]);

const dialog = ref(false);
const dialogDelete = ref(false);
const loading = ref(false);
const xpTotal = ref(0);
const search = ref('');

const defaultItem = {
  character: character.value._id,
  specialAttribute: '',
  qualifier: '',
  rank: 1,
  notes: '',
};
const editedItem = ref({ ...defaultItem });

const calcXPTotal = () => {
  let total = 0;

  if (character.value.specialAttributes) {
    character.value.specialAttributes.forEach((attrib) => {
      total += getXPCostOfAttribute(attrib.specialAttribute.xpCosts, attrib.rank);
    });
  }
  xpTotal.value = total;
};

calcXPTotal();

const openEditDialog = (attribute) => {
  editedItem.value = { ...attribute };
  dialog.value = true;
};

const openAddDialog = () => {
  editedItem.value = { ...defaultItem };
  dialog.value = true;
};

const onRowClick = (event, details) => {
  const { item } = details;
  openEditDialog(item);
};

const confirmDelete = (item) => {
  editedItem.value = item;
  dialogDelete.value = true;
};

const deleteItem = async () => {
  // saving.value = true;
  // const index = items.value.indexOf(editedItem.value);
  const index = character.value.specialAttributes.indexOf(editedItem.value);
  const { _id: id } = editedItem.value;
  await characterStore.deleteCharacterSpecialAttribute(id);
  // items.value.splice(index, 1);
  character.value.specialAttributes.splice(index, 1);
  calcXPTotal();
};

const closeDialog = () => {
  dialog.value = false;
  calcXPTotal();
};

const saveAttribute = async (attribute) => {
  loading.value = true;

  if (attribute._id) {
    const updateResponse = await characterStore.updateCharacterSpecialAttribute(attribute);
    if (updateResponse.success) {
      const index = character.value.specialAttributes.findIndex((i) => i._id === attribute._id);
      character.value.specialAttributes[index] = { ...attribute };
      const charResponse = await characterStore.getCharacter(character.value._id);
      if (charResponse.success) {
        character.value = charResponse.data;
      }
    }
  } else {
    const addResponse = await characterStore.addCharacterSpecialAttribute(attribute);
    if (addResponse.success) {
      const charResponse = await characterStore.getCharacter(character.value._id);
      if (charResponse.success) {
        character.value = charResponse.data;
      }
    }
  }
  loading.value = false;
  closeDialog();
};

watch(xpTotal, (newValue, oldValue) => {
  const diff = newValue - oldValue;
  characterStore.spendXP(character.value, system.value, diff);
  characterStore.updateCharacter(character.value).then();
});

const onSearchUpdate = (val) => {
  search.value = val;
};

const filteredItems = computed(() => {
  let tempItems = character.value.specialAttributes;

  if (search.value) {
    const searchVal = search.value.toLowerCase();
    tempItems = tempItems.filter((item) => item.attributeName.toLowerCase().includes(searchVal));
  }
  return tempItems;
});
</script>

<template>
  <div>
    <list-container @search-update="onSearchUpdate">
      <template #middle>
        {{ system.specialAttributesSettings.title }} [{{ xpTotal }}]
      </template>
      <template #button>
        <v-btn v-if="!offlineStore.offlineMode" v-vis-perms="'Character.CREATE'" class="float-right" variant="flat" color="primary" @click="openAddDialog">
          Add {{ system.specialAttributesSettings.itemLabel }}
        </v-btn>
      </template>
      <v-data-table
        v-model:items-per-page="itemsPerPage"
        :items-per-page="-1"
        :headers="headers"
        :items="filteredItems"
        item-value="name"
        class="elevation-1"
        :no-data-text="`No ${system.specialAttributesSettings.label}.`"
        @click:row="onRowClick"
      >
        <template #[`item.attributeName`]="{ item }">
          <info-button :message="item.specialAttribute.description" class="mr-2" />

          <span class="name">{{ item.specialAttribute.attributeName }}</span>
          <span class="xp">XP: {{ getXPCostOfAttribute(item.specialAttribute.xpCosts, item.rank) }}</span>
          <span v-if="item.rank > 1" class="rank-column">Rank: {{ item.rank }}</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <delete-icon v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" @click.stop="confirmDelete(item)" />
        </template>

        <template #bottom></template>
      </v-data-table>
    </list-container>

    <attribute-edit-dialog v-model="dialog" :attributes="system?.specialAttributes || []" :edited-entity="editedItem" :save-function="saveAttribute" />
    <delete-dialog v-model="dialogDelete" :delete-function="deleteItem" :label="editedItem?.specialAttribute?.attributeName" />
  </div>
</template>

<style scoped>
.name {
  font-weight: 600;
}

.xp {
  display: block;
  clear: both;
  font-size: 0.7rem;
}

.rank-column {
  display: block;
  clear: both;
  font-size: 0.7rem;
}
</style>
