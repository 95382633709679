import axios from 'axios';
import { getCookie } from '@/utils/cookies';

const tokenCookieName = 'charbroil-access-token';

axios.interceptors.request.use((request) => {
  const token = getCookie(tokenCookieName);
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

export default axios;
