<script setup>
import { computed } from 'vue';
import InfoButton from '@/components/InfoButton.vue';

const props = defineProps({
  modelValue: {
    type: [Boolean, undefined],
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  info: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const fieldValue = computed({
  get: () => !!props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <div>
    <v-checkbox
      v-model="fieldValue"
      v-bind="$attrs"
      :label="label"
      :readonly="disabled"
    >
      <template #append>
        <info-button :message="info" />
      </template>
    </v-checkbox>
  </div>
</template>

<style scoped>

</style>
