<script setup>

import {
  inject,
  watch,
  ref,
} from 'vue';

const character = inject('printCharacter');
const listItems = ref([]);

defineExpose({ listItems });

const props = defineProps({
  characterListId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  sortFieldName: {
    type: String,
    default: null,
  },
  sortDescending: {
    type: Boolean,
    default: false,
  },
  filterFieldName: {
    type: String,
    default: null,
  },
  filterFieldMinValue: {
    type: Number,
    default: 0,
  },
});

// noinspection FunctionWithMultipleReturnPointsJS
const sortByField = (a, b) => {
  let retval = 0;
  if (props.sortFieldName) {
    if (a?.fields?.[props.sortFieldName] < b?.fields?.[props.sortFieldName]) {
      retval = -1;
    }
    if (a?.fields?.[props.sortFieldName] > b?.fields?.[props.sortFieldName]) {
      retval = 1;
    }
    if (a?.fields?.[props.sortFieldName] !== undefined && b?.fields?.[props.sortFieldName] === undefined) {
      return -1;
    }
    if (a?.fields?.[props.sortFieldName] === undefined && b?.fields?.[props.sortFieldName] !== undefined) {
      return 1;
    }
  }

  if (props.sortDescending) {
    retval = -retval;
  }

  return retval;
};

watch(character, (newValue) => {
  if (!newValue.loading) {
    const characterList = character.characterLists.find((list) => list.listDefinition === props.characterListId);
    if (characterList) {
      listItems.value = characterList.items;

      if (props.filterFieldName) {
        listItems.value = listItems.value.filter((item) => item?.fields?.[props.filterFieldName] !== undefined && item?.fields?.[props.filterFieldName] >= props.filterFieldMinValue);
      }

      if (props.sortFieldName) {
        listItems.value = listItems.value.sort(sortByField);
      }
    }
  }
}, { deep: true });

</script>

<template>
  <div class="character-list">
    <div class="section-title">
      {{ title }}
    </div>
    <div class="list">
      <div v-for="item in listItems" :key="item._id" class="list-item">
        <slot name="listItem" :item="item">
          <span class="label">{{ item.label }}</span> - <span class="description">{{ item.description }}</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.character-list {
  width: 100%;
  display: inline-block;
  border: 1px solid lightgrey;
  min-height: 1in;
}

.section-title {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 1rem;
  background-color: black;
  color: white;
  text-align: center;
}

.list {
  display: inline-block;
  width: 100%;
}

.list-item {
  display: inline-block;
  width: 100%;
  padding-top: 0.05in;
  padding-bottom: 0.05in;
  padding-left: 5px;
  border-bottom: 1px solid lightgrey;
}

/* These are also defined as duplicates in the parent CharacterViewPrintable because of style scoping. */
.label {
  font-size: 0.8rem;
  width: 100%;
}

.label {
  font-weight: bold;
}

.description {
}
</style>
