import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.scss';
import { md2 } from 'vuetify/blueprints';

import { createVuetify } from 'vuetify';

export default createVuetify({
  blueprint: md2,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#673ab7',
          secondary: '#3f51b5',
          accent: '#ffc107',
          error: '#f44336',
          warning: '#ff5722',
          info: '#2196f3',
          success: '#4caf50',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#673ab7',
          secondary: '#3f51b5',
          accent: '#ffc107',
          error: '#f44336',
          warning: '#ff5722',
          info: '#2196f3',
          success: '#4caf50',
        },
      },
    },
  },
});
