<script setup>
import { getCalculatedFieldValuesForObject } from '@/utils/variable-math';
import { computed } from 'vue';
import InfoButton from '@/components/InfoButton.vue';

const props = defineProps({
  characterObject: {
    type: Object,
    required: true,
  },
  fieldName: {
    type: String,
    required: true,
  },
  fieldLabel: {
    type: String,
    required: true,
  },
  fieldDescription: {
    type: String,
    default: '',
  },
  showOnlyCalculated: {
    type: Boolean,
    required: true,
  },
  hideInfoButton: {
    type: Boolean,
    default: false,
  },
});

const calculatedValue = computed(() => {
  let retval = 0;

  if (props?.characterObject?.fields) {
    const calculatedValues = getCalculatedFieldValuesForObject(props.characterObject);
    retval = props.characterObject.fields[props.fieldName];

    if (calculatedValues[props.fieldName] !== undefined) {
      retval = calculatedValues[props.fieldName];
    }
  }
  return retval;
});

const originalValue = computed(() => {
  let retval = 0;
  if (props?.characterObject?.fields) {
    retval = props.characterObject.fields[props.fieldName];
  }
  return retval;
});
</script>

<template>
  <div>
    <info-button v-if="!hideInfoButton" :message="fieldDescription" />
    <span v-if="showOnlyCalculated">{{ fieldLabel }}<span v-if="fieldLabel">: </span>{{ calculatedValue }}</span>
    <span v-else>{{ fieldLabel }}<span v-if="fieldLabel">: </span>{{ originalValue }} <span v-if="originalValue !== calculatedValue">({{ calculatedValue }})</span></span>
  </div>
</template>

<style scoped>

</style>
