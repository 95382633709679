<script setup>
import useAppStore from '@/store/app-store';
import useOfflineStore from '@/store/offline-store';
import AccountAppBarWidget from '@/components/AccountAppBarWidget.vue';

const appStore = useAppStore();
const offlineStore = useOfflineStore();

</script>

<template>
  <v-app-bar class="d-print-none">
    <v-app-bar-nav-icon @click="appStore.toggleDrawer()" />
    <v-app-bar-title>
      <span v-if="offlineStore.offlineMode" class="offline"><v-icon icon="mdi-cloud-off" class="mr-4" />OFFLINE</span>
      <slot></slot>
    </v-app-bar-title>
    <account-app-bar-widget class="mr-4" />
  </v-app-bar>
</template>

<style scoped>
.offline {
  background-color: red;
  padding: 10px;
}
</style>
