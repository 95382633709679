<template>
  <div>
    <!--    <v-alert type="error" closable class="mb-4">-->
    <!--      <v-alert-title>-->
    <!--        Regression Alert-->
    <!--      </v-alert-title>-->
    <!--      <p>Recent changes are expected to introduce regressions.  In particular, be on the lookout for error messages talking about "id", "_id", or "ObjectId".  Also, watch for difficulty retrieving specific records.</p>-->
    <!--      <p>These changes are wide-reaching, so every part of the application is now considered untested.</p>-->
    <!--      <p>This untested phase should be pretty short-lived.</p>-->
    <!--    </v-alert>-->
    <v-alert type="warning" closable>
      <v-alert-title>
        Development Version
      </v-alert-title>
      <p>
        This is just a development version. A subset of complete functionality is available here for testing.
        Expect some things to be broken.
      </p>
      <p>Please make a note of any major issues.</p>
    </v-alert>
  </div>
</template>

<script setup>

</script>
