import {
  createAction,
  updateAction,
  deleteAction,
} from '@/store/_helpers/server-action-helpers';

const effectDefinitionActions = {
  // eslint-disable-next-line require-await
  async addEffectDefinition(effectDefinition, errorHandler) {
    const url = '/api/effectdefs';
    return createAction(url, effectDefinition, 'effect definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateEffectDefinition(effectDefinition, errorHandler) {
    const url = `/api/effectdefs/${effectDefinition._id}`;
    return updateAction(url, effectDefinition, 'effect definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteEffectDefinition(effectDefID, errorHandler) {
    const url = `/api/effectdefs/${effectDefID}`;
    return deleteAction(url, 'effect definition', effectDefID, false, errorHandler);
  },
};

export default effectDefinitionActions;
