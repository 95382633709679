<script setup>
import { computed, inject } from 'vue';
import { calculateXPCostForCharacter } from '@/utils/xp-math';

const character = inject('printCharacter');
const system = inject('printSystem');

const xpSpent = computed(() => {
  let total = 0;
  if (!character.loading && !system.loading) {
    total = calculateXPCostForCharacter(character, system);
  }
  return total;
});
</script>

<template>
  <div style="height: 1in;">
    <div class="w3-row">
      <div class="w3-half">
        <div class="w3-row">
          <div class="w3-col label" style="width: 0.9in;">
            Quester:
          </div>
          <div class="w3-col no-overflow quester" style="width: 3in;">
            {{ character.quester.fullName }}
          </div>
        </div>
      </div>
      <div class="w3-half">
        <div class="w3-row">
          <div class="w3-col label" style="width: 0.9in;">
            Character:
          </div>
          <div class="w3-col no-overflow character" style="width: 3in;">
            {{ character.characterName }}
          </div>
        </div>
      </div>
    </div>
    <div class="w3-row" style="height: 0.62in;">
      <div class="w3-col" style="width: 0.9in;">
        <span class="label">Description:</span>
      </div>
      <div class="w3-col no-overflow" style="width: 3in;max-height: 0.6in;">
        <span class="description">{{ character.description }}</span>
      </div>
      <div class="w3-col s6 w3-row">
        <div class="w3-col s5 wallet-box">
          <span class="wallet-label">
            Wallet
          </span>
          <div class="wallet-value">
            {{ character.moneyAvailable }}
          </div>
        </div>
        <div class="w3-col s3 xp-box">
          <span class="xp-label">
            XP Total
          </span>
          <div class="xp-value">
            {{ xpSpent }}
          </div>
        </div>
        <div class="w3-col s3 xp-box">
          <span class="xp-label">
            XP Avail
          </span>
          <div class="xp-value">
            {{ character.xpAvailable }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/views/character-view-printable/styles/layout.css";

.label {
  font-size: 0.15in;
}

.quester, .character {
  font-size: 0.25in;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 0.20in;
}

.xp-box, .wallet-box {
  display: inline-block;
  white-space: nowrap;
  border: 1px solid black;
  border-radius: 3px;
  margin: 5px;
  height: 0.5in;
}

.xp-box {
  width: 0.75in;
}

.xp-label, .wallet-label {
  position: relative;
  top: -8px;
  left: 10px;
  padding: 1px 2px 1px 2px;
  background-color: white;
}

.xp-value, .wallet-value {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 0.3in;
  vertical-align: top;
  margin-top: -15px;
  margin-left: 5px;
}

//.wallet-section {
//  float: left;
//}
.wallet-box {
  width: 1.25in;
}
</style>
