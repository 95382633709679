<script setup>
import { useRoute } from 'vue-router';
import { watchEffect, ref } from 'vue';
import useAuthStore from '@/store/auth-store';

const route = useRoute();
const authStore = useAuthStore();

const token = ref('');
const password = ref('');
const passwordAgain = ref('');
const success = ref(false);
const loading = ref(false);

watchEffect(() => {
  token.value = route.query.token;
});

const resetPassword = async () => {
  loading.value = true;
  const response = await authStore.resetPassword(password.value, token.value);
  if (response.success) {
    success.value = true;
  }
  loading.value = false;
};
</script>

<template>
  <div>
    <v-card style="max-width: 400px;" variant="elevated" elevation="22" class="mx-auto bg-secondary" :loading="loading">
      <v-card-title>
        Reset Password
      </v-card-title>
      <v-card-text v-if="success">
        <v-alert type="success">
          You successfully reset your password.  You may now login.
        </v-alert>
      </v-card-text>
      <v-card-subtitle v-if="!success">
        Please enter a new secure password ...
        <v-alert v-if="password && passwordAgain && password !== passwordAgain" type="error">
          Passwords do not match
        </v-alert>
      </v-card-subtitle>
      <v-card-text v-if="!success">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="password" label="Password" type="password" variant="solo-filled" hide-details />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="passwordAgain" label="Confirm Password" type="password" variant="solo-filled" hide-details />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!success" class="ma-3">
        <v-btn color="white" variant="elevated" class="ml-auto" @click="resetPassword">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped>

</style>
