<script setup>
import {
  ref,
  inject,
  onMounted,
  watch,
} from 'vue';
import FormText from '@/components/form/FormText.vue';
import { MdPreview } from 'md-editor-v3';

const myProps = defineProps({
  systemAttribute: {
    type: Object,
    required: true,
  },
});

const findCharacterAttribute = (systemAttributeID) => character.value.characterAttributes.find((attrib) => attrib.systemAttribute === systemAttributeID);
const character = inject('character');
const makeCharacterDirty = inject('makeCharacterDirty');

const dialog = ref(false);
const foundAttrib = ref({});
const attributeDefinition = ref({});

watch(dialog, (newValue) => {
  if (!newValue && myProps.systemAttribute.qualifiable && foundAttrib.value) {
    makeCharacterDirty();
  }
});

onMounted(() => {
  foundAttrib.value = findCharacterAttribute(myProps.systemAttribute?._id ?? myProps.systemAttribute);
  attributeDefinition.value = { ...myProps.systemAttribute };
});
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template #activator="{ props }">
      <v-badge v-if="attributeDefinition.qualifiable" color="red" dot>
        <v-icon v-bind="props" size="small" color="blue">
          mdi-information-outline
        </v-icon>
      </v-badge>
      <v-icon v-else v-bind="props" size="small" color="blue">
        mdi-information-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <md-preview v-model="attributeDefinition.description" style="word-wrap: break-word;" />
            </v-col>
            <v-col v-if="attributeDefinition.qualifiable && foundAttrib" cols="12">
              <form-text v-model="foundAttrib.qualifier" label="Qualifier" info="This power can be qualified with a few descriptive words." />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>

<style>
.md-editor-preview {
  word-break: normal;
}
</style>
