<script setup>
import { ref } from 'vue';
import useAuthStore from '@/store/auth-store';
import router from '@/router/index';

const authStore = useAuthStore();

const loading = ref(false);
const dialogLogin = ref(false);
const dialogForgot = ref(false);
const dialogMailSent = ref(false);
const credentials = ref({
  email: '',
  password: '',
});

const login = async () => {
  loading.value = true;
  const response = await authStore.login(credentials.value);

  if (response.success) {
    credentials.value.email = '';
    credentials.value.password = '';
    loading.value = false;
    dialogLogin.value = false;
    router.push('/').then();
  }
};

const startForgot = () => {
  dialogLogin.value = false;
  dialogForgot.value = true;
  dialogMailSent.value = false;
};

const startLogin = () => {
  dialogLogin.value = true;
  dialogForgot.value = false;
  dialogMailSent.value = false;
};

const showMailSent = () => {
  dialogLogin.value = false;
  dialogForgot.value = false;
  dialogMailSent.value = true;
};

const forgot = async () => {
  loading.value = true;
  const response = await authStore.forgotPassword(credentials.value.email);
  if (response.success) {
    showMailSent();
  }
  loading.value = false;
};
</script>

<template>
  <div>
    <v-dialog v-model="dialogLogin" activator="parent" max-width="500px">
      <template #activator="{ props }">
        <slot v-bind="props" name="activator">
          <v-btn color="accent">
            Login
          </v-btn>
        </slot>
      </template>
      <v-card title="Login" :loading="loading">
        <v-alert type="warning" class="ma-2" closable>
          This is a development version. Nothing here is complete and everything is broken. It is
          not intended to work. Only log in if you understand.
        </v-alert>
        <v-card-text>
          <v-text-field v-model="credentials.email" label="Email" />
          <v-text-field v-model="credentials.password" label="Password" type="password" />
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" text="Forgot Password" @click="startForgot" />
          <v-btn variant="flat" text="Login" type="submit" class="ml-auto" @click="login" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogForgot" max-width="500px">
      <v-card title="Forgot Password" :loading="loading">
        <v-alert type="info" class="ma-2">
          Enter the email used for your Charbroil account and we will send you a link you can use to reset your password.
        </v-alert>
        <v-card-text>
          <v-text-field v-model="credentials.email" label="Email" hint="Enter your email to recover your password." persistent-hint />
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" text="Login" type="submit" @click="startLogin" />
          <v-btn variant="flat" text="Recover Password" class="ml-auto" @click="forgot" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMailSent" max-width="500px">
      <v-alert type="success">
        <v-alert-title>Success</v-alert-title>
        A recovery email has been sent to {{ credentials.email }}.
      </v-alert>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
