<script setup>
import {
  computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import useAppStore from '@/store/app-store';
import useMeStore from '@/store/users/me-store';
import useOfflineStore from '@/store/offline-store';

const appStore = useAppStore();
const meStore = useMeStore();
const offlineStore = useOfflineStore();

const router = useRouter();
const { smAndDown } = useDisplay();

const version = import.meta.env.VERSION;

const myProps = defineProps({
  menu: {
    type: String,
    required: true,
  },
});

const sortMenuItemComparator = (itemA, itemB) => {
  const sortA = itemA?.meta?.sortOrder || 0;
  const sortB = itemB?.meta?.sortOrder || 0;
  return sortA - sortB;
};

const availableMenuItems = computed(() => {
  const routes = router.getRoutes().sort(sortMenuItemComparator);
  const thisMenuRoutes = routes.filter((route) => route?.meta?.menus && route.meta.menus.includes(myProps.menu));

  const allowedRoutes = thisMenuRoutes.filter((route) => {
    let allowed;
    if ((route?.meta?.requiresAuth || route?.meta?.requiredPermissions) && !meStore.isAuthenticated) {
      allowed = false;
    } else {
      allowed = meStore.hasPermissions(route.meta.requiredPermissions);
    }

    if (offlineStore.offlineMode) {
      if (!route?.meta?.offlineable) {
        allowed = false;
      }
    }

    return allowed;
  });

  const items = allowedRoutes.map((route) => {
    const { meta } = route;
    return {
      icon: meta.icon,
      label: meta.label,
      to: route.path,
    };
  });

  return items.sort(sortMenuItemComparator);
});

const go = (to) => {
  if (router) {
    router.push(to);
  }
  if (smAndDown.value) {
    appStore.hideDrawer();
  }
};
</script>

<template>
  <v-navigation-drawer v-if="(availableMenuItems.length > 0) && appStore.drawerIsVisible" permanent class="d-print-none">
    <v-list>
      <v-list-item
        v-for="menuItem in availableMenuItems"
        :key="menuItem.label"
        link
        @click="go(menuItem.to)"
      >
        <template #prepend>
          <v-icon>
            {{ menuItem.icon }}
          </v-icon>
        </template>

        <v-list-item-title>
          {{ menuItem.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <template #append>
      <div v-if="meStore.isAuthenticated" class="pa-2">
        <span class="float-right version">Ver. {{ version }}dev</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<style scoped>
.version {
  font-size: 11px;
}
</style>
