<script setup>
import { computed } from 'vue';
import InfoButton from '@/components/InfoButton.vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  itemTitle: {
    type: String,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  info: {
    type: String,
    default: '',
  },
  returnObject: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const update = (event) => {
  const { value } = event.target;
  emit('update:modelValue', value);
};

const fieldValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const items = computed(() => {
  const retval = props.disableNoSelection ? [] : [''];
  retval.push(...props.options);
  return retval;
});
</script>

<template>
  <div>
    <v-select
      v-model="fieldValue"
      v-bind="$attrs"
      :label="label"
      :items="items"
      :item-title="itemTitle"
      :item-value="itemValue"
      :return-object="returnObject"
      :clearable="clearable"
      :readonly="disabled"
      @change="update"
    >
      <template #append>
        <info-button :message="info" />
      </template>
    </v-select>
  </div>
</template>

<style scoped>

</style>
