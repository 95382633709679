import { createRouter, createWebHistory } from 'vue-router';
// import useAuthStore from '@/store/auth-store';
import useMeStore from '@/store/users/me-store';
// import { hasPermissionMatch } from '@/utils/has-permission-match';

import MainLayout from '@/layouts/MainLayout.vue';

// import CharacterList from '@/views/characters/CharacterList.vue';
import CharacterView from '@/views/character-view/CharacterView.vue';
// import SystemList from '@/views/systems/QuestModuleTemplateList.vue';
// import QuesterForm from '@/views/questers/QuesterForm.vue';
// import SystemView from '@/views/systems/QuestModuleTemplateView.vue';
import HomeView from '@/views/HomeView.vue';
// import AdminDashboard from '@/views/AdminDashboard.vue';
// import UserList from '@/views/auth/UserList.vue';
import StarQuestCharacterViewPrintable from '@/views/character-view-printable/systems/StarQuestCharacterViewPrintable.vue';
import AdventureQuestTwoCharacterViewPrintable from '@/views/character-view-printable/systems/AdventureQuestTwoCharacterViewPrintable.vue';
import AdventureQuestTwoCharacterViewMultiPrintable from '@/views/character-view-printable/systems/AdventureQuestTwoCharacterViewMultiPrintable.vue';
import StarQuestCharacterViewMultiPrintable from '@/views/character-view-printable/systems/StarQuestCharacterViewMultiPrintable.vue';
// import RoleList from '@/views/auth/RoleList.vue';
// import TenantList from '@/views/auth/TenantList.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import NoNavLayout from '@/layouts/NoNavLayout.vue';
// import DocumentationView from '@/views/DocumentationView.vue';
// import GoOfflineView from '@/views/GoOfflineView.vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          requiresAuth: false,
          menus: ['main'],
          label: 'Dashboard',
          icon: 'mdi-home',
          sortOrder: 10,
          offlineable: true,
        },
        component: HomeView,
      }, {
        path: '/characters',
        name: 'characters',
        component: () => import('@/views/characters/CharacterList.vue'),
        // component: CharacterList,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['Character.READ'],
          menus: ['main'],
          label: 'Characters',
          icon: 'mdi-drama-masks',
          sortOrder: 100,
          offlineable: true,
        },
      }, {
        path: '/questers',
        name: 'questers',
        component: () => import('@/views/questers/QuestersList.vue'),
        meta: {
          requiresAuth: true,
          requiredPermissions: ['Quester.READ'],
          menus: ['main'],
          label: 'Questers',
          icon: 'mdi-human',
          sortOrder: 110,
        },
      }, {
        path: '/events',
        name: 'events',
        component: () => import('@/views/events/EventList.vue'),
        meta: {
          requiresAuth: true,
          requiredPermissions: ['Quester.READ'],
          menus: ['main'],
          label: 'Events',
          icon: 'mdi-human-queue',
          sortOrder: 115,
        },
      // }, {
      //   path: '/offline',
      //   name: 'offline',
      //   component: () => import('@/views/GoOfflineView.vue'),
      //   // component: GoOfflineView,
      //   meta: {
      //     requiresAuth: true,
      //     requiredPermissions: ['Character.READ', 'System.READ', 'Quester.READ'],
      //     menus: ['main'],
      //     label: 'Offline Mode',
      //     icon: 'mdi-cloud-off',
      //     sortOrder: 117,
      //     offlineable: true,
      //   },
      }, {
        path: 'systems',
        name: 'systems',
        component: () => import('@/views/systems/SystemList.vue'),
        // component: SystemList,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['GameSystem.UPDATE'],
          menus: ['main'],
          label: 'Systems',
          icon: 'mdi-cog',
          sortOrder: 210,
        },
      }, {
        path: 'users',
        name: 'users',
        component: () => import('@/views/auth/UserList.vue'),
        // component: UserList,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['User.READ'],
          menus: ['main'],
          label: 'Users',
          icon: 'mdi-account-multiple',
          sortOrder: 220,
        },
      // }, {
      //   path: 'tenants',
      //   name: 'tenants',
      //   component: () => import('@/views/auth/TenantList.vue'),
      //   // component: TenantList,
      //   meta: {
      //     requiresAuth: true,
      //     requiredPermissions: ['Tenant.UPDATE'],
      //     menus: ['main'],
      //     label: 'Tenants',
      //     icon: 'mdi-office-building-marker',
      //     sortOrder: 235,
      //   },
      }, {
        path: 'roles',
        name: 'roles',
        component: () => import('@/views/auth/RoleList.vue'),
        // component: RoleList,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['Role.UPDATE'],
          menus: ['main'],
          label: 'Roles',
          icon: 'mdi-account-tie-hat',
          sortOrder: 230,
        },
      // }, {
      //   path: 'templates',
      //   name: 'templates',
      //   component: () => import('@/views/quest-modules/QuestModuleTemplateList.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     requiredPermissions: ['ModuleTemplate.UPDATE'],
      //     menus: ['main'],
      //     label: 'Module Templates',
      //     icon: 'mdi-cog',
      //     sortOrder: 300,
      //   },
      // }, {
      //   path: '/admin',
      //   name: 'dashboard',
      //   component: () => import('@/views/AdminDashboard.vue'),
      //   // component: AdminDashboard,
      //   meta: {
      //     requiresAuth: true,
      //     requiredPermissions: ['AdminMenu.READ'],
      //     label: 'Admin Dashboard',
      //     icon: 'mdi-shield-crown',
      //     menus: ['main'],
      //     sortOrder: 999,
      //   },
      }, {
        path: '/quester/:id',
        name: 'quester',
        component: () => import('@/views/questers/QuesterForm.vue'),
        // component: QuesterForm,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['Quester.READ'],
        },
      }, {
        path: '/character/:id',
        name: 'character',
        component: CharacterView,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['Character.READ'],
          offlineable: true,
        },
        props: (route) => ({ query: route.query.tab }),
      }, {
        path: 'system/:id',
        name: 'system',
        component: () => import('@/views/systems/SystemView.vue'),
        // component: SystemView,
        meta: {
          requiresAuth: true,
          requiredPermissions: ['GameSystem.READ'],
        },
      // }, {
      //   path: 'template/:id',
      //   name: 'template',
      //   component: () => import('@/views/quest-modules/QuestModuleTemplateView.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     requiredPermissions: ['ModuleTemplate.UPDATE'],
      //   },
      }, {
        path: '/docs',
        name: 'Help',
        meta: {
          requiresAuth: false,
          menus: ['main'],
          label: 'Help',
          icon: 'mdi-help-circle',
          sortOrder: 900,
        },
        component: () => import('@/views/DocumentationView.vue'),
        // component: DocumentationView,
      },
    ],
  }, {
    path: '/character/:id/printable/657b73fd6291787aad4619ae',
    name: 'character-printable-starquest',
    component: StarQuestCharacterViewPrintable,
    meta: {
      requiresAuth: true,
      requiredPermissions: ['Character.READ'],
      offlineable: true,
    },
  }, {
    path: '/character/:id/printable/658dd1c1f590b072cf0574f0',
    name: 'character-printable-adventure-quest-2',
    component: AdventureQuestTwoCharacterViewPrintable,
    meta: {
      requiresAuth: true,
      requiredPermissions: ['Character.READ'],
      offlineable: true,
    },
  }, {
    path: '/character/starquest/multiprint/:ids+',
    name: 'character-multiprintable-starquest',
    component: StarQuestCharacterViewMultiPrintable,
    meta: {
      requiresAuth: true,
      requiredPermissions: ['Character.READ'],
      offlineable: true,
    },
  }, {
    path: '/character/aq2/multiprint/:ids+',
    name: 'character-multiprintable-adventure-quest-2',
    component: AdventureQuestTwoCharacterViewMultiPrintable,
    meta: {
      requiresAuth: true,
      requiredPermissions: ['Character.READ'],
      offlineable: true,
    },
  }, {
    path: '/nonav',
    component: NoNavLayout,
    meta: {
      requiresAuth: false,
    },
    children: [{
      path: 'reset',
      name: 'reset',
      meta: {
        requiresAuth: false,
      },
      component: ResetPassword,
    }],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, from, next) => {
  const meStore = useMeStore();
  const { requiresAuth } = to.meta;
  const requiredPermissions = to.meta.requiredPermissions || [];

  const passesAuthentication = meStore.isAuthenticated || !requiresAuth;
  const passesPermissions = !requiredPermissions.length || meStore.hasPermissions(requiredPermissions);

  if (passesAuthentication && passesPermissions) {
    next();
  } else {
    next('/');
  }
});

export default router;
