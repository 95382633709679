import {
  createAction,
  updateAction,
  deleteAction,
  getOneAction,
} from '@/store/_helpers/server-action-helpers';

const objectDefinitionActions = {
  // eslint-disable-next-line require-await
  async getObjectDefinition(objectDefID, errorHandler) {
    const url = `/api/objectdefs/${objectDefID}`;
    return getOneAction(url, 'object definition', objectDefID, false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async addObjectDefinition(objectDefinition, errorHandler) {
    const url = '/api/objectdefs';
    return createAction(url, objectDefinition, 'object definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateObjectDefinition(objectDefinition, errorHandler) {
    const url = `/api/objectdefs/${objectDefinition._id}`;
    return updateAction(url, objectDefinition, 'object definition', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteObjectDefinition(objectDefID, errorHandler) {
    const url = `/api/objectdefs/${objectDefID}`;
    return deleteAction(url, 'object definition', objectDefID, false, errorHandler);
  },
};

export default objectDefinitionActions;
