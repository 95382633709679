<script setup>
import {
  computed,
  inject,
  ref,
  provide, watch,
} from 'vue';
import CharacterAttribute from './PowerGridAttribute.vue';

defineProps({
  tier: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['showing-change']);

const anyChildrenVisible = ref(false);
const xpTierTotal = ref(0);

const baseColor = inject('baseColor');
const showEmpties = inject('showEmpties');

const updateParentXPSubtotal = inject('updateXPPathTotal');

updateParentXPSubtotal(xpTierTotal.value, 0);

const updateXPSubtotal = (value, oldValue = 0) => {
  xpTierTotal.value += parseInt(value, 10) - parseInt(oldValue, 10);
};

const updateChildrenVisibility = (val) => {
  if (val) {
    anyChildrenVisible.value = true;
  }
};

const showThis = computed(() => {
  if (anyChildrenVisible.value) {
    emit('showing-change', true);
  }
  return anyChildrenVisible.value;
});

provide('updateXPTierTotal', updateXPSubtotal);

watch(xpTierTotal, (newValue, oldValue) => {
  updateParentXPSubtotal(newValue, oldValue);
});
</script>

<template>
  <v-col v-if="showThis || showEmpties" class="v-col-12 v-col-md-4 mt-0 pt-1 pr-sm-6" :class="'bg-' + baseColor + '-lighten-2'">
    <h5 :class="{ 'font-italic': tier.extended }" class="mx-2 my-auto">
      {{ tier.tierName }} <span v-if="xpTierTotal">[{{ xpTierTotal }}]</span>
    </h5>
    <character-attribute
      v-for="attribute in tier.attributes"
      :key="attribute._id"
      :disabled="disabled"
      :system-attribute="attribute"
      :number-of-ranks="tier.numberOfLevels"
      :xp-costs="tier.xpCosts"
      @showing-change="updateChildrenVisibility"
    />
  </v-col>
</template>

<style scoped>

</style>
