<script setup>
import {
  computed,
  provide,
  ref,
  inject,
} from 'vue';
import useCharacterStore from '@/store/character/character-store';
import SystemPathTier from './PowerGridPathTier.vue';

const characterStore = useCharacterStore();

const myProps = defineProps({
  path: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const baseColor = computed(() => myProps.path.color || 'grey');
const pathName = computed(() => myProps.path.pathName || 'Path');
const basicTiers = computed(() => myProps.path.tiers.filter((t) => !t.extended && !t.tabbed));
const extendedTiers = computed(() => myProps.path.tiers.filter((t) => t.extended && !t.tabbed));

const anyChildrenVisible = ref(false);
const xpPathTotal = ref(0);

const character = inject('character');
const system = inject('characterSystem');

const updateChildrenVisibility = (val) => {
  if (val) {
    anyChildrenVisible.value = true;
  }
};

const updateXPSubtotal = (value, oldValue = 0) => {
  xpPathTotal.value += parseInt(value, 10) - parseInt(oldValue, 10);
  characterStore.updateXPSpent(character.value, system.value);
};

provide('baseColor', baseColor);
provide('updateXPPathTotal', updateXPSubtotal);

const showEmpties = inject('showEmpties');
</script>

<template>
  <div v-if="anyChildrenVisible || showEmpties" class="mb-4">
    <v-row :class="'bg-' + baseColor" class="hidden-sm-and-up">
      <v-col class="horizontal-pathname" :class="'bg-' + baseColor + '-lighten-3'">
        {{ pathName }} <span class="path-xp">[{{ xpPathTotal }}]</span>
      </v-col>
    </v-row>
    <v-row :class="'bg-' + baseColor" xclass="mr-sm-4">
      <v-col class="vertical-pathname hidden-xs" :class="'bg-' + baseColor + '-lighten-3'">
        {{ pathName }} <span class="path-xp">[{{ xpPathTotal }}]</span>
      </v-col>
      <v-col>
        <v-row>
          <system-path-tier v-for="tier in basicTiers" :key="tier.name" :tier="tier" :disabled="disabled" @showing-change="updateChildrenVisibility" />
        </v-row>
        <v-row>
          <system-path-tier v-for="tier in extendedTiers" :key="tier.name" :tier="tier" :disabled="disabled" @showing-change="updateChildrenVisibility" />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.vertical-pathname {
  writing-mode: vertical-lr;
  text-align: center;
  max-width: 40px;
  font-size: 18px;
  font-weight: bold;
  transform: rotate(180deg);
  margin-left: 12px;
}

.horizontal-pathname {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.path-xp {
  margin-top: 15px;
}
</style>
