<script setup>
import {
  ref,
  computed,
} from 'vue';
import SearchBox from '@/components/SearchBox.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  availableMods: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue', 'added']);

const search = ref('');

const headers = ref([
  {
    title: 'Modification Name',
    align: 'start',
    sortable: true,
    key: 'modificationName',
  }, {
    title: 'Description',
    align: 'start',
    sortable: true,
    key: 'description',
  },
]);

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const close = () => {
  dialog.value = false;
};

const filteredModifications = computed(() => {
  let effects = [];

  if (props?.availableMods?.length) {
    effects = props.availableMods.filter((m) => {
      const modNameMatch = m.modificationName.toLowerCase().includes(search.value.toLowerCase());
      const descriptionMatch = m.description.toLowerCase().includes(search.value.toLowerCase());
      return modNameMatch || descriptionMatch;
    });
  }
  return effects;
});

const onRowClick = (event, details) => {
  const { item } = details;
  emit('added', item);
  close();
};
</script>

<template>
  <div>
    <v-dialog v-model="dialog">
      <v-card class="px-1 px-sm-4">
        <v-card-title>
          Add Modification
        </v-card-title>
        <v-card-text class="px-1 px-sm-4">
          <v-container class="px-1">
            <v-col cols="12" class="px-0 px-sm-4">
              <v-row>
                <v-col cols="12" class="px-0 px-sm-4">
                  <v-data-table
                    :items="filteredModifications"
                    items-per-page="-1"
                    :headers="headers"
                    :search="search"
                    @click:row="onRowClick"
                  >
                    <template #top>
                      <search-box v-model="search" />
                    </template>
                    <!--                    <template #[`item.effect`]="{ item }">-->
                    <!--                      {{ getEffectDescriptor(item) }}-->
                    <!--                    </template>-->
                    <template #bottom></template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-darken-1" variant="text" @click="close">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
