<script setup>
import {
  onMounted,
  provide,
} from 'vue';

import PrintablePowerGrid from '@/views/character-view-printable/sections/PrintablePowerGrid.vue';
import PrintableExtendedPowerGrid from '@/views/character-view-printable/sections/PrintableExtendedPowerGrid.vue';
import PrintableSpecialAttributesSection from '@/views/character-view-printable/sections/PrintableSpecialAttributesSection.vue';
import PrintableCharacterList from '@/views/character-view-printable/sections/PrintableCharacterList.vue';
import PrintableHeaderStarQuest from '@/views/character-view-printable/sections/PrintableHeaderStarQuest.vue';
import CharacterObjectFieldWithCalculations from '@/components/CharacterObjectFieldWithCalculations.vue';

const props = defineProps({
  character: {
    type: Object,
    required: true,
  },
  system: {
    type: Object,
    required: true,
  },
});

provide('printCharacter', props.character);
provide('printSystem', props.system);

const getEquipmentItems = () => {
  const items = [];
  const listDef = props.system.listDefinitions.find((l) => l.listName.toLowerCase() === 'equipment');
  if (listDef) {
    const charList = props.character.characterLists.find((l) => l.listDefinition === listDef._id);
    if (charList) {
      items.push(...charList.items);
    }
  }
  return items;
};

const getObjectParenthetical = (item) => {
  let parenthetical = item.objectDefinition.objectName;
  if (item.objectDefinition._id === '65a6bc4a2ba2f6122ce83623') {
    parenthetical = item?.fields?.weaponclassification;
  }
  return parenthetical;
};

onMounted(() => {
  // eslint-disable-next-line vue/no-mutating-props
  props.character.equipment = getEquipmentItems();
});
</script>

<template>
  <div class="char-sheet no-anything">
    <div class="page page-1 no-anything">
      <printable-header-star-quest />
      <div class="page-body w3-row">
        <div class="w3-col s8">
          <div class="w3-row">
            <printable-power-grid />
          </div>
          <div class="w3-row">
            <printable-extended-power-grid />
          </div>
          <div class="w3-row">
            <printable-character-list title="Relationships" character-list-id="65a6c3e12ba2f6122ce848f1">
              <template #listItem="{ item }">
                <div class="name-label">
                  <span class="label">{{ item.label }}</span> <span class="parenthetical">({{ item.fields['relationshiptype'] }})</span> - <span class="description">{{ item.description }}</span>
                </div>
              </template>
            </printable-character-list>
          </div>
        </div>
        <div class="w3-col s4 full-height" style="border: 1px solid lightgrey;border-top: 0;">
          <div class="w3-row">
            <printable-special-attributes-section />
          </div>
          <div class="w3-row">
            <div class="notes-section">
              <div class="notes-title">
                Notes
              </div>
              <div class="notes-box" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page-other">
      <div class="w3-row">
        <printable-character-list title="Equipment" character-list-id="65a6b8142ba2f6122ce83506" sort-field-name="value" sort-descending>
          <template #listItem="{ item }">
            <div class="equipment-item">
              <div class="equipment-fixed-subsection">
                <div class="equipment-label">
                  {{ item.label }} <span class="parenthetical">({{ getObjectParenthetical(item) }})&nbsp;</span>
                </div>
                <div class="equipment-description">
                  - {{ item.description }}
                </div>
                <div class="equipment-value">
                  <!--                  {{ item?.fields?.value || 0 }}-->
                  <character-object-field-with-calculations v-if="item?.fields?.value" field-name="value" :character-object="item" field-label="" show-only-calculated hide-info-button />
                </div>
              </div>

              <div v-if="item.modifications && item.modifications.length" class="equipment-mods-subsection">
                <div v-for="mod in item.modifications" :key="mod._id" class="mod">
                  <b>{{ mod.modification.modificationName }}</b> - {{ mod.modification.description }}
                </div>
              </div>
            </div>
          </template>
        </printable-character-list>
      </div>
      <div class="w3-row">
        <div class="w3-col s6">
          <printable-character-list title="Droids" character-list-id="65a6b8342ba2f6122ce83535">
            <template #listItem="{ item }">
              <span class="label">{{ item.label }}</span> - <span class="description">{{ item.description }}</span>
              <div class="equipment-value">
                <character-object-field-with-calculations v-if="item?.fields?.value" field-name="value" :character-object="item" field-label="" show-only-calculated hide-info-button />
              </div>
              <div>
                Powers: {{ item?.fields?.powersknown }}
              </div>
              <div v-if="item.modifications && item.modifications.length" class="equipment-mods-subsection">
                <div v-for="mod in item.modifications" :key="mod._id" class="mod">
                  <b>{{ mod.modification.modificationName }}</b> - {{ mod.modification.description }}
                </div>
              </div>
            </template>
          </printable-character-list>
        </div>
        <div class="w3-col s6">
          <printable-character-list title="Vehicles" character-list-id="65a6b8662ba2f6122ce83564">
            <template #listItem="{ item }">
              <span class="label">{{ item.label }}</span> - <span class="description">{{ item.description }}</span>
              <div class="equipment-value">
                <character-object-field-with-calculations v-if="item?.fields?.value" field-name="value" :character-object="item" field-label="" show-only-calculated hide-info-button />
              </div>
              <div>
                Hull <b>{{ item?.fields?.hull || 0 }}</b> | Engines <b>{{ item?.fields?.engines || 0 }}</b> | Shields <b>{{ item?.fields?.shields || 0 }}</b> | Sensors <b>{{ item?.fields?.sensors || 0 }}</b> | Weapons <b>{{ item?.fields?.weapons || 0 }}</b>
              </div>
              <div v-if="item.modifications && item.modifications.length" class="equipment-mods-subsection">
                <div v-for="mod in item.modifications" :key="mod._id" class="mod">
                  <b>{{ mod.modification.modificationName }}</b> - {{ mod.modification.description }}
                </div>
              </div>
            </template>
          </printable-character-list>
        </div>
        <div class="w3-col s6">
          <printable-character-list title="Accomplishments" character-list-id="65c27d3180f4a7092ac96dea" sort-field-name="displaypriority" sort-descending filter-field-name="displaypriority" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/views/character-view-printable/styles/layout.css";

.char-sheet {
  font-size: 11px;
}

.page {
  width: 8in;
  height: 10.5in;
  vertical-align: top;
}

.page-1 {
  margin: 0;
  padding: 0;
}

.page-other {
  page-break-before: always;
}

.page-1 .page-body {
  width: 100%;
  height: calc(100% - 1in);
}

/* Per-item classes */
.item-value {
  width: 0.5in;
  float: right;
  display: inline-block;
  vertical-align: top;
  font-size: 0.15in;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid lightgrey;
  border-radius: 3px;
  padding: 2px;
}

.mod {
  padding-left: 20px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}

.mod::before {
  font-size: 0.2in;
  line-height: 0.2in;
  content: "+";
  margin-right: 8px;
  color: black;
}

.equipment-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.equipment-fixed-subsection {
  max-height: 0.3in;
  overflow: hidden;
  text-overflow: ellipsis;
}

.equipment-label {
  font-size: 0.8rem;
  font-weight: bold;
  display: inline-block;
}

.equipment-description {
  display: inline-block;
  font-size: 0.11in;
}

.equipment-mods-subsection {
  display: inline-block;
  width: 100%;
  font-size: 0.11in;
}

.equipment-value {
  display: inline-block;
  /*width: 1in;*/
  float: right;
  vertical-align: top;
  font-size: 0.15in;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}

/* These are also defined as duplicates in the child PrintableCharacterList because of style scoping. */
.name-label {
  display: inline-block;
  font-size: 0.8rem;
  width: calc(100% - 0.5in);
}

.label {
  font-weight: bold;
}

.parenthetical {
}

.description {
}

.notes-section {
  width: 100%;
}

.notes-box {
  display: inline-block;
  vertical-align: top;
}

.notes-title {
  background-color: black;
  color: white;
  text-align: center;
}
</style>
