import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const pathActions = {
  // eslint-disable-next-line require-await
  async addPath(systemID, path, errorHandler) {
    const url = `/api/systems/${systemID}/paths`;
    return createAction(url, path, 'path', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updatePath(systemID, path, errorHandler) {
    const url = `/api/systems/${systemID}/paths`;
    return updateAction(url, path, 'path', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deletePath(systemID, id, errorHandler) {
    const url = `/api/systems/${systemID}/paths/${id}`;
    return deleteAction(url, 'path', id, false, errorHandler);
  },
};

export default pathActions;
