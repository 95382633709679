// eslint-disable-next-line import/prefer-default-export
export const getDefaultValueBasedOnFieldDef = (fieldDef) => {
  let defVal;
  const fieldType = fieldDef.type;
  const { defaultValue } = fieldDef;

  if (defaultValue === undefined || defaultValue === null) {
    switch (fieldType) {
      case 'Number':
      case 'Rating':
      case 'Variable':
        defVal = 0;
        break;
      case 'Checkbox':
        defVal = false;
        break;
      default:
        defVal = '';
    }
  } else {
    defVal = defaultValue;
  }

  return defVal;
};
