<script setup>
import { inject } from 'vue';
import PrintablePowerGridAttribute from '@/views/character-view-printable/sections/PrintablePowerGridAttribute.vue';

const system = inject('printSystem');

const getBasicTiers = (path) => path.tiers.filter((t) => !t.extended);
</script>

<template>
  <table class="power-grid">
    <thead class="power-grid-header">
      <tr>
        <th class="path-first-column">
        </th>
        <th class="tier-column">
          Core
        </th>
        <th class="tier-column">
          Heroic
        </th>
        <th class="tier-column">
          Paragon
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="path in system.paths" :key="path._id">
        <td class="path-first-column path-name">
          {{ path.pathName }}
        </td>

        <td v-for="tier in getBasicTiers(path)" :key="tier._id" class="tier-column">
          <tr v-for="attrib in tier.attributes" :key="attrib._id">
            <td class="attribute-label">
              {{ attrib.attributeName }}
            </td>
            <td class="attribute-rank">
              <!--              <v-rating model-value="3" density="compact" length="5" class="ma-0 pa-0" empty-icon="mdi-checkbox-blank-outline" readonly full-icon="mdi-checkbox-marked" size="x-small" color="black" />-->
              <printable-power-grid-attribute :system-attribute="attrib" :number-of-ranks="5" />
            </td>
          </tr>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
.power-grid {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.power-grid-header {
  background-color: black;
  color: white;
}

.path-first-column {
  width: 0.2in;
  max-width: 0.2in;
  background-color: black;
  color: white;
}

.tier-column {
  width: 1.5in;
  max-width: 1.5in;
  border: 1px solid lightgrey;
}

.attribute-label {
  width: 0.8in;
  max-width: 0.8in;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* border: 1px solid red; */
}

.attribute-rank {

}

/* .vertical-pathname {
  writing-mode: vertical-lr;
  text-align: center;
  max-width: 40px;
  font-size: 18px;
  font-weight: bold;
  transform: rotate(180deg);
  margin-left: 12px;
} */
.path-name {
  writing-mode: vertical-lr;
  text-align: center;
  transform: rotate(180deg);
  width: 30px;
  font-weight: bold;
}
</style>
