<script setup>

import {
  ref,
  provide,
  inject,
  computed,
} from 'vue';
import useMeStore from '@/store/users/me-store';
import useOfflineStore from '@/store/offline-store';
import SystemPath from './PowerGridPath.vue';

const meStore = useMeStore();
const offlineStore = useOfflineStore();
const showEmpties = ref(true);
provide('showEmpties', showEmpties);

const system = inject('characterSystem');

const paths = computed(() => (system.value.paths ? system.value.paths.filter((p) => !p.hidden) : []));

const disabled = ref(!meStore.hasPermissions('Character.UPDATE') || offlineStore.offlineMode);
</script>

<template>
  <div>
    <v-toolbar class="mb-3 pa-0" style="width: 100%; max-height: 50px;">
      <div>
        <v-switch v-model="showEmpties" label="Show Empties" class="ml-4 mt-2 mb-auto" />
      </div>
    </v-toolbar>
    <system-path v-for="path in paths" :key="path.pathName" :path="path" :disabled="disabled" />
  </div>
</template>

<style scoped>

</style>
