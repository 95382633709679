import { defineStore } from 'pinia';
import pathActions from '@/store/system/actions/path-actions';
import tierActions from '@/store/system/actions/tier-actions';
import attributeActions from '@/store/system/actions/attribute-actions';
import listDefinitionActions from '@/store/system/actions/list-definition-actions';
import objectDefinitionActions from '@/store/system/actions/object-definition-actions';
import {
  createAction,
  updateAction,
  getManyAction,
  getOneAction,
} from '@/store/_helpers/server-action-helpers';
import variableDefinitionActions from '@/store/system/actions/variable-definition-actions';
import characterFieldDefinitionActions from '@/store/system/actions/character-field-definition-actions';
import effectDefinitionActions from '@/store/system/actions/effect-definition-actions';
import modificationDefinitionActions
  from '@/store/system/actions/modification-definition-actions';
import specialAttributeActions from '@/store/system/actions/special-attribute-actions';

const useSystemStore = defineStore('systems', {
  state: () => ({
    systems: [],
  }),
  getters: {},
  actions: {
    // eslint-disable-next-line require-await
    async getSystems(errorHandler) {
      let retval = this.systems;
      if (retval.length === 0) {
        retval = await getManyAction('/api/systems', 'systems', true, errorHandler);
      }
      return retval;
    },
    // eslint-disable-next-line require-await
    async getSystem(id, errorHandler) {
      const url = `/api/systems/${id}`;
      return getOneAction(url, 'system', id, true, errorHandler);
    },
    // eslint-disable-next-line require-await
    async updateSystem(system, errorHandler) {
      const url = `/api/systems/${system._id}`;
      return updateAction(url, system, 'system', false, errorHandler);
    },
    // eslint-disable-next-line require-await
    async createSystem(system, errorHandler) {
      const url = '/api/systems';
      return createAction(url, system, 'system', false, errorHandler);
    },
    ...pathActions,
    ...tierActions,
    ...attributeActions,
    ...listDefinitionActions,
    ...objectDefinitionActions,
    ...variableDefinitionActions,
    ...characterFieldDefinitionActions,
    ...effectDefinitionActions,
    ...modificationDefinitionActions,
    ...specialAttributeActions,
  },
});

export default useSystemStore;
