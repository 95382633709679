import { ref } from 'vue';
import { deleteCookie, setCookie } from '@/utils/cookies';

export const offlineMode = ref(document.cookie.includes('charbroil-offline'));

export const updateOfflineMode = () => {
  offlineMode.value = document.cookie.includes('charbroil-offline');
};

export const setOfflineCookie = () => {
  setCookie('charbroil-offline', true);
  updateOfflineMode();
};

export const removeOfflineCookie = () => {
  deleteCookie('charbroil-offline');
  updateOfflineMode();
};
