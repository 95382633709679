<script setup>
import {
  computed,
  inject,
  ref,
  watch,
} from 'vue';
import ChameleonField from '@/components/ChameleonField.vue';
import FormText from '@/components/form/FormText.vue';
import { getDefaultValueBasedOnFieldDef } from '@/utils/get-default-value-based-on-field-def';
import { getEffectDescriptor } from '@/utils/get-effect-descriptor';
import AddModificationDialog from '@/views/character-view/sections/character-list-section/AddModificationDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import useOfflineStore from '@/store/offline-store';
import InfoButton from '@/components/InfoButton.vue';

const myProps = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  saveFunction: {
    type: Function,
    required: true,
  },
  editedEntity: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue', 'updated', 'added']);

const system = inject('characterSystem');
const character = inject('character');

const offlineStore = useOfflineStore();

const loading = ref(false);
const entity = ref(myProps.editedEntity);
const modificationDialog = ref(false);
const dialogDelete = ref(false);

const dialog = computed({
  get: () => myProps.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const ensureAllFieldsExist = (item) => {
  if (item.fields === undefined) {
    item.fields = {};
  }
  item.objectDefinition.fields.forEach((fieldDef) => {
    if (item.fields[fieldDef.name] === undefined) {
      item.fields[fieldDef.name] = getDefaultValueBasedOnFieldDef(fieldDef);
    }
  });
};

watch(() => myProps.editedEntity, (newData) => {
  ensureAllFieldsExist(newData);
  entity.value = newData;
});

const close = () => {
  dialog.value = false;
};

const saveEntity = async () => {
  loading.value = true;
  const saved = await myProps.saveFunction(entity.value);
  if (saved) {
    if (!entity.value._id) {
      emit('added', entity.value);
    } else {
      emit('updated', entity.value);
    }
  }
  close();
  loading.value = false;
};

const setLocalValue = (fieldName, val) => {
  entity.value.fields[fieldName] = val;
};

const openAddModificationDialog = () => {
  // editedItem.value = { ...defaultItem };
  // editedItem.value.objectDefinition = objectDefinition;
  modificationDialog.value = true;
};

const deleteModByIndex = (index) => {
  entity.value.modifications.splice(index, 1);
};

const deleteEffect = (effect) => {
  const index = entity.value.effects.find((e) => e._id === effect._id);
  entity.value.effects.splice(index, 1);
};

const getEffectByID = (id) => system.value.effectDefinitions.find((e) => e._id === id);

const addModification = (modification) => {
  if (entity.value.modifications === undefined) {
    entity.value.modifications = [];
  }
  entity.value.modifications.push({
    modification,
    qualifier: '',
  });

  // entity.value.modifications.push({
  //   modification,
  //   qualifier: '',
  //   _id: modification._id,
  //   id: modification._id,
  // });
  // modification.effectDefinitions.forEach((e) => {
  //   const effect = getEffectByID(e?.id || e);
  //   if (effect) {
  //     entity.value.effects.push({
  //       modification,
  //       effect,
  //     });
  //   }
  // });
};

const availableMods = computed(() => {
  let modDefs = [];
  const characterListID = entity.value.characterList._id || entity.value.characterList;
  const charList = character.value.characterLists.find((l) => l._id === characterListID);
  if (charList) {
    const listDefinitionID = charList.listDefinition._id || charList.listDefinition;
    const list = system.value.listDefinitions.find((l) => l._id === listDefinitionID);
    if (list) {
      modDefs = system.value.modificationDefinitions.filter((def) => list.allowedModificationDefinitions.includes(def._id));
    }
  }
  return modDefs;
});
</script>

<template>
  <div>
    <v-dialog v-model="dialog" class="mobile-dialog">
      <v-card :loading="loading" class="px-0">
        <v-card-title class="bg-secondary">
          {{ entity._id ? 'Edit' : 'Add' }} {{ entity.objectDefinition.objectName }}
        </v-card-title>
        <v-card-text class="px-0">
          <v-container fluid class="px-1 px-sm-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-container class="px-1">
                  <v-row>
                    <v-col cols="12">
                      <form-text v-model="entity.label" v-vis-perms="'Character.UPDATE'" :readonly="offlineStore.offlineMode" label="Label" info="Optionally give this item a name or label." />
                    </v-col>
                    <v-col cols="12">
                      <form-text v-model="entity.description" v-vis-perms="'Character.UPDATE'" :readonly="offlineStore.offlineMode" label="Description" info="Optionally give this item a short description." />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row v-if="entity.objectDefinition.fields">
                        <v-col v-for="(fieldDef, index) in entity.objectDefinition.fields.sort((a, b) => a.sortOrder - b.sortOrder)" :key="index" cols="12">
                          <chameleon-field v-vis-perms="'Character.UPDATE'" :disabled="offlineStore.offlineMode" :model-value="entity.fields[fieldDef.name]" :field-def="fieldDef" @update:modelValue="(val) => setLocalValue(fieldDef.name, val)" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col v-if="availableMods.length" cols="12" md="6">
                <v-card density="compact">
                  <v-card-title class="bg-black">
                    Modifications
                    <v-btn v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" class="float-right" variant="flat" @click="openAddModificationDialog">
                      Add Modification
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-list lines="two" density="compact">
                      <v-list-item
                        v-for="(mod, index) in entity.modifications"
                        :key="index"
                        density="compact"
                        class="border-b"
                      >
                        <v-list-item-title>
                          {{ mod.modification.modificationName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ mod.modification.description }}
                        </v-list-item-subtitle>
                        <template #append>
                          <delete-icon v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" @click="deleteModByIndex(index)" />
                        </template>
                        <v-list lines="two" density="compact" class="ml-4">
                          <v-list-item
                            v-for="(effect, i) in mod.modification.effectDefinitions"
                            :key="i"
                            density="compact"
                          >
                            <v-list-item-title>
                              {{ getEffectByID(effect?._id ?? effect).effectName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <info-button :message="effect.description" class="mr-2" />
                              {{ getEffectDescriptor(getEffectByID(effect?._id ?? effect)) }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-list>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue-darken-1" variant="text" @click="close">
            Cancel
          </v-btn>
          <v-btn v-if="!offlineStore.offlineMode" v-vis-perms="'Character.UPDATE'" color="blue-darken-1" variant="text" @click="saveEntity">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

      <add-modification-dialog v-if="!offlineStore.offlineMode" v-model="modificationDialog" :available-mods="availableMods" @added="addModification" />
      <delete-dialog v-if="!offlineStore.offlineMode" v-model="dialogDelete" :delete-function="deleteEffect" />
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
