import axios from '@/plugins/axios';
import useErrorStore from '@/store/errors-store';
import { getAllObjects, getObject } from '@/store/_helpers/offline-storage';
import actions from '@/store/_helpers/actions';

const makeErrorMessage = (operation, err) => {
  let msg = `Error attempting ${operation}:`;

  switch (err?.response?.status) {
    case 403:
    case 401:
      msg = `${msg} Unauthorized`;
      break;
    case 404:
      msg = `${msg} Not found`;
      break;
    default:
      msg = `${msg} ${err?.response?.data || 'unknown error'}`;
  }
  return msg;
};

// eslint-disable-next-line require-await
const makeOfflineRequest = async (options, errorHandler) => {
  const {
    entity,
    action,
    defaultData,
    id,
    offlineable,
  } = options;
  const operation = makeOperation(action, entity);
  const retval = {
    success: false,
    operation,
    data: defaultData,
  };

  if (offlineable) {
    try {
      if (action === actions.GETALL) {
        retval.data = await getAllObjects(entity);
      } else if (action === actions.GET && id) {
        retval.data = await getObject(entity, id);
      }
      retval.success = true;
    } catch (err) {
      // Handle errors, such as no data available in offline mode
      retval.error = err.message;
      errorHandler(err.message);
      if (defaultData !== undefined) {
        retval.data = defaultData;
        retval.success = true; // Optionally mark as success if default data is considered acceptable
      }
    }
  } else {
    retval.success = true;
  }
  return retval;
};

const makeOnlineRequest = async (url, method, options, errorHandler, onFailure) => {
  const {
    entity,
    action,
    data,
    defaultData,
  } = options;
  const operation = makeOperation(action, entity);
  const retval = {
    success: false,
    operation,
  };

  try {
    const axiosPromise = axios({ url, method, data });
    const response = await axiosPromise;
    const resultData = response.data;
    const { status } = response;
    const success = status === 200;

    retval.success = success;
    retval.statusCode = status;
    if (success) {
      retval.data = resultData;
    } else {
      if (status !== 401) {
        onFailure(response, operation);
      }
      retval.error = response;
    }
  } catch (err) {
    const msg = makeErrorMessage(operation, err);
    retval.success = false;
    retval.statusCode = err.response ? err.response.status : 500;
    retval.error = msg;
    if (defaultData !== undefined) {
      retval.data = defaultData;
    }
    errorHandler(msg);
  }
  return retval;
};

const makeOperation = (action, entity) => (action && entity ? `${action} ${entity}` : 'server operation');

export default async (url, method, options) => {
  const errorStore = useErrorStore();
  const action = options?.action;
  const entity = options?.entity;
  const id = options?.id;
  const data = options?.data;
  const defaultData = options?.defaultData;
  const errorHandler = options?.errorHandler ?? errorStore.handleError;
  const offlineable = !!options?.offlineable;
  const onFailure = errorStore.handleFailedResponse;

  const requestOptions = {
    entity,
    action,
    defaultData,
    offlineable,
    data,
  };
  if (id) {
    requestOptions.id = id;
  }

  let retval;
  const offlineMode = document.cookie.includes('charbroil-offline');

  if (offlineMode) {
    retval = await makeOfflineRequest(requestOptions, errorHandler);
  } else {
    retval = await makeOnlineRequest(url, method, requestOptions, errorHandler, onFailure);
  }

  return retval;
};
