import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const characterObjectActions = {
  // eslint-disable-next-line require-await
  async addCharacterObject(charObject, errorHandler) {
    const url = '/api/charobjects';
    return createAction(url, charObject, 'character object', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateCharacterObject(charObject, errorHandler) {
    const url = `/api/charobjects/${charObject._id}`;
    return updateAction(url, charObject, 'character object', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteCharacterObject(charObjectID, errorHandler) {
    const url = `/api/charobjects/${charObjectID}`;
    return deleteAction(url, 'character object', charObjectID, false, errorHandler);
  },
};

export default characterObjectActions;
