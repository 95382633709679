import { defineStore } from 'pinia';
import { deleteCookie, getCookie, setCookie } from '@/utils/cookies';
import processAPIPromise from '@/store/_helpers/processAPIPromise';
import useMeStore from '@/store/users/me-store';
import axios from 'axios';

const tokenCookieName = 'charbroil-access-token';

const useAuthStore = defineStore('user', {
  state: () => ({}),
  getters: {
    name: () => {
      const meStore = useMeStore();
      const { user } = meStore;
      let name;
      switch (true) {
        case (!!user.full):
          name = user.full;
          break;
        case (!!user.firstName && !!user.lastName):
          name = `${user.firstName} ${user.lastName}`;
          break;
        case (!!user.firstName):
          name = user.firstName;
          break;
        case (!!user.lastName):
          name = user.lastName;
          break;
        default:
          name = user.email || '';
      }
      return name;
    },
    isAuthenticated: () => {
      const meStore = useMeStore();
      return meStore.isAuthenticated;
    },
  },
  actions: {
    async checkInitialAuthState(errorHandler) {
      const token = this.getToken();

      if (token) {
        const meStore = useMeStore();
        try {
          this.setToken(token);
          await meStore.loadMe(errorHandler);
        } catch (error) {
          // If the token refresh or user details fetch fails
          this.clearToken();
          meStore.clearUser();
        }
      } // else we just treat them like any anonymous user
    },
    async login(creds, errorHandler) {
      const authCreds = {};
      if (typeof creds?.email === 'string' && typeof creds?.password === 'string') {
        authCreds.email = creds.email;
        authCreds.password = creds.password;
      } else {
        throw new Error('Must supply a valid email and password');
      }

      const requestOptions = {
        operation: 'logging in',
        errorHandler,
        data: authCreds,
        defaultData: {},
      };

      const response = await processAPIPromise('/auth/login', 'POST', requestOptions);

      if (response.success) {
        const meStore = useMeStore();
        const token = response?.data?.token || '';

        if (token) {
          this.setToken(token);
          await meStore.loadMe(errorHandler);
        }
      }
      return { success: response.success };
    },
    async logout() {
      const meStore = useMeStore();
      await axios.get('/auth/logout');
      meStore.clearUser();
      this.clearToken();
    },
    // eslint-disable-next-line require-await
    async forgotPassword(email, errorHandler) {
      const requestOptions = {
        operation: 'processing forgot password',
        defaultData: {},
        data: { email },
        errorHandler,
      };
      return processAPIPromise('/auth/forgot', 'POST', requestOptions);
    },
    // eslint-disable-next-line require-await
    async resetPassword(password, token, errorHandler) {
      const requestOptions = {
        operation: 'resetting password',
        defaultData: {},
        data: {
          password,
          token,
        },
        errorHandler,
      };
      return processAPIPromise('/auth/reset', 'POST', requestOptions);
    },
    setToken(accessToken) {
      if (accessToken) {
        setCookie(tokenCookieName, accessToken);
      } else {
        this.clearToken();
      }
    },
    getToken() {
      return getCookie(tokenCookieName);
    },
    clearToken() {
      deleteCookie(tokenCookieName);
    },
  },
});

export default useAuthStore;
