<script setup>
import useQuesterStore from '@/store/questers-store';
import useOfflineStore from '@/store/offline-store';
import { ref, onMounted, inject } from 'vue';

const questerStore = useQuesterStore();
const offlineStore = useOfflineStore();

const questers = ref([]);

const character = inject('character');
const makeCharacterDirty = inject('makeCharacterDirty');

onMounted(async () => {
  const questersResponse = await questerStore.getQuesters();
  if (questersResponse.success) {
    questers.value = questersResponse.data;
    questers.value = questers.value.map((q) => {
      if (!q.fullName) {
        q.fullName = `${q.firstName} ${q.lastName}`;
      }
      return q;
    });
  }
});

</script>

<template>
  <v-card>
    <v-card-title class="bg-black">
      Settings
    </v-card-title>
    <v-card-text class="px-0">
      <v-container fluid>
        <v-row class="flex-nowrap" dense>
          <v-col class="label text-no-wrap flex-grow-0 flex-shrink-1">
            Quester:
          </v-col>
          <v-col class="flex-grow-1">
            <v-autocomplete
              v-model="character.quester"
              v-vis-perms="'Character.UPDATE'"
              :readonly="offlineStore.offlineMode"
              label="Quester"
              :items="questers"
              auto-select-first
              item-title="fullName"
              item-value="_id"
              @update:model-value="makeCharacterDirty"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="flex-nowrap" dense>
          <v-col class="label text-no-wrap flex-grow-0 flex-shrink-1">
            Character Name:
          </v-col>
          <v-col class="flex-grow-1">
            <v-text-field
              v-model="character.characterName"
              v-vis-perms="'Character.UPDATE'"
              :readonly="offlineStore.offlineMode"
              label="Character Name"
              hide-details
              @update:model-value="makeCharacterDirty"
            />
          </v-col>
        </v-row>
        <v-row class="flex-nowrap">
          <v-col cols="12">
            <v-textarea
              v-model="character.description"
              v-vis-perms="'Character.UPDATE'"
              :readonly="offlineStore.offlineMode"
              label="Description"
              style="font-style: italic;"
              hide-details
              @update:model-value="makeCharacterDirty"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.label {
  font-weight: bold;
}
</style>
