<script setup>
import {
  inject,
  ref,
  computed,
  watch,
} from 'vue';
import PowerGridAttributeInfoButton from '@/views/character-view/sections/power-grid-section/PowerGridAttributeInfoButton.vue';
import { getXPCostOfAttribute } from '@/utils/xp-math';
import useCharacterStore from '@/store/character/character-store';

const characterStore = useCharacterStore();

const myProps = defineProps({
  systemAttribute: {
    type: Object,
    required: true,
  },
  numberOfRanks: {
    type: Number,
    default: 5,
  },
  xpCosts: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['showing-change']);

const showEmpties = inject('showEmpties');
const character = inject('character');
const system = inject('characterSystem');
const makeCharacterDirty = inject('makeCharacterDirty');

const updateParentXPSubtotal = inject('updateXPTierTotal');

const getCharacterAttributeRank = (attributeID, defaultValue = 0) => {
  const characterAttributes = character.value.characterAttributes || [];
  const attrib = characterAttributes.find((a) => a.systemAttribute === attributeID);
  return attrib ? attrib.rank : defaultValue;
};

const attributeRank = ref(getCharacterAttributeRank(myProps.systemAttribute._id, myProps.systemAttribute.defaultValue));
const xpSubtotal = ref(getXPCostOfAttribute(myProps.xpCosts, attributeRank.value));

updateParentXPSubtotal(xpSubtotal.value, 0);

const showThis = computed(() => {
  const charAttribValue = attributeRank.value;

  if (charAttribValue) {
    emit('showing-change', true);
  }
  return !!charAttribValue;
});

const findCharacterAttribute = (systemAttributeID) => character.value.characterAttributes.find((attrib) => attrib.systemAttribute === systemAttributeID);

const removeAttribute = (systemAttributeID) => {
  const foundAttribIndex = character.value.characterAttributes.findIndex((attrib) => attrib.systemAttribute === systemAttributeID);
  if (foundAttribIndex !== -1) {
    character.value.characterAttributes.splice(foundAttribIndex, 1);
  }
};

const addAttribute = (systemAttribute, rank) => {
  character.value.characterAttributes.push({
    systemAttribute,
    rank,
  });
};

const updateValue = (systemAttributeID, value) => {
  makeCharacterDirty();
  if (value === 0) {
    removeAttribute(systemAttributeID);
  } else {
    const foundAttrib = findCharacterAttribute(systemAttributeID);

    if (!foundAttrib) {
      addAttribute(systemAttributeID, value);
    } else {
      foundAttrib.rank = value;
    }
  }
};

watch(attributeRank, (newRank, oldRank) => {
  const newValue = getXPCostOfAttribute(myProps.xpCosts, newRank);
  const oldValue = getXPCostOfAttribute(myProps.xpCosts, oldRank);
  xpSubtotal.value = newValue;
  const diff = newValue - oldValue;
  characterStore.spendXP(character.value, system.value, diff);
});

watch(xpSubtotal, (newValue, oldValue) => {
  updateParentXPSubtotal(newValue, oldValue);
});
</script>

<template>
  <v-sheet v-if="showThis || showEmpties" class="d-flex pa-1">
    <span style="width: 150px;" class="d-inline-block text-truncate">
      <power-grid-attribute-info-button :system-attribute="systemAttribute" />
      {{ systemAttribute.attributeName }}
      <v-tooltip
        activator="parent"
        location="start"
        open-delay="1000"
        :text="systemAttribute.attributeName"
      />
    </span>
    <v-rating
      v-model="attributeRank"
      :disabled="disabled"
      clearable
      density="compact"
      :length="numberOfRanks"
      empty-icon="mdi-checkbox-blank-outline"
      full-icon="mdi-checkbox-marked"
      @update:model-value="(val) => updateValue(systemAttribute._id, val)"
    >
    </v-rating>
    <div class="xp">
      <span v-if="xpSubtotal">
        [{{ xpSubtotal }}]
      </span>
    </div>
  </v-sheet>
</template>

<style scoped>
.xp {
  width: 30px;
  text-align: right;
}
</style>
