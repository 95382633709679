import { createAction, updateAction, deleteAction } from '@/store/_helpers/server-action-helpers';

const characterSpecialAttributeActions = {
  // eslint-disable-next-line require-await
  async addCharacterSpecialAttribute(charSpecialAttribute, errorHandler) {
    const url = '/api/charspecials';
    return createAction(url, charSpecialAttribute, 'character special attributes', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async updateCharacterSpecialAttribute(charSpecialAttribute, errorHandler) {
    const url = `/api/charspecials/${charSpecialAttribute._id}`;
    return updateAction(url, charSpecialAttribute, 'character special attributes', false, errorHandler);
  },
  // eslint-disable-next-line require-await
  async deleteCharacterSpecialAttribute(charSpecialAttributeID, errorHandler) {
    const url = `/api/charspecials/${charSpecialAttributeID}`;
    return deleteAction(url, 'character special attributes', charSpecialAttributeID, false, errorHandler);
  },
};

export default characterSpecialAttributeActions;
