<script setup>
import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';

import useCharacterStore from '@/store/character/character-store';
import useSystemStore from '@/store/system/system-store';
import AdventureQuestTwoCharacterViewPrintableComponent from '@/views/character-view-printable/systems/AdventureQuestTwoCharacterViewPrintableComponent.vue';

const characterStore = useCharacterStore();
const systemStore = useSystemStore();
const route = useRoute();

const loading = ref(true);
const system = ref({ loading: true });
const characters = ref({ loading: true });

onBeforeMount(async () => {
  const selectedCharIDs = route.params?.ids ?? [];
  const retrievedCharacters = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const characterID of selectedCharIDs) {
    // eslint-disable-next-line no-await-in-loop
    const charResponse = await characterStore.getCharacter(characterID);
    if (charResponse.success) {
      retrievedCharacters.push(charResponse.data);
    }
  }
  characters.value = retrievedCharacters;

  const sysResponse = await systemStore.getSystem(characters.value[0].system);
  if (sysResponse.success) {
    system.value = sysResponse.data;
  }
  loading.value = false;
});
</script>

<template>
  <v-progress-circular v-if="loading" indeterminate />
  <div v-else>
    <div v-for="character in characters" :key="character._id" class="char-sheet no-anything">
      <adventure-quest-two-character-view-printable-component :system="system" :character="character" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/views/character-view-printable/styles/layout.css";

.char-sheet {
  font-size: 11px;
}

.page {
  width: 8in;
  height: 10.5in;
  vertical-align: top;
}

.page-1 {
  margin: 0;
  padding: 0;
}

.page-other {
  page-break-before: always;
}

.page-1 .page-body {
  width: 100%;
  height: calc(100% - 1in);
}

</style>
