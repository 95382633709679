import { createApp } from 'vue';
import useAuthStore from '@/store/auth-store';
import useMeStore from '@/store/users/me-store';
import useErrorStore from '@/store/errors-store';
import { updateOfflineMode } from '@/utils/offline';
import App from './App.vue';

// Plugins
import registerPlugins from './plugins';
import vuetify from './plugins/vuetify';
import pinia from './plugins/pinia';
import router from './router';

const app = createApp(App);

updateOfflineMode();

registerPlugins(app);

app.use(vuetify);
app.use(pinia);

const errorStore = useErrorStore();
const authStore = useAuthStore();
const meStore = useMeStore();

// @todo: Move custom directives to their own file for initialization, but for now ...
app.directive('vis-perms', (el, binding) => {
  if (!meStore.hasPermissions(binding.value)) {
    el.style.display = 'none';
  }
});

app.directive('dis-perms', (el, binding) => {
  if (!meStore.hasPermissions(binding.value)) {
    el.disabled = true;
    el.style.pointerEvents = 'none'; // Optionally prevent mouse events
    el.style.opacity = 0.5;
  }
});

(async () => {
  await authStore.checkInitialAuthState();

  const token = authStore.getToken();
  if (token) {
    await meStore.loadMe((err) => {
      // We really don't care that this failed.  It just means they aren't authenticated.
      console.log('Initial load user failed', err.message);
    });
  }

  app.use(router);

  app.provide('handleFailedResponse', errorStore.handleFailedResponse);
  app.provide('setError', errorStore.handleError);

  app.mount('#app');
})();
