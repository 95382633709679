// eslint-disable-next-line import/prefer-default-export
export const getEffectDescriptor = (effect) => {
  let descriptor = '';
  if (effect?.affectedVariable) {
    descriptor = effect.affectedVariable;
    if (effect?.effectMultiplier) {
      descriptor = `${descriptor}, +${effect.effectMultiplier}x`;
    }
    if (effect?.effectAddition) {
      descriptor = `${descriptor}, +${effect.effectAddition}`;
    }
  }
  return descriptor;
};
