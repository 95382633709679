<script setup>
import {
  computed,
  ref,
  watch,
  onMounted,
} from 'vue';
import FormTextarea from '@/components/form/FormTextarea.vue';
import FormNumber from '@/components/form/FormNumber.vue';
import FormCheckbox from '@/components/form/FormCheckbox.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import FormText from '@/components/form/FormText.vue';
import FormRating from '@/components/form/FormRating.vue';

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean, null],
    required: true,
  },
  fieldDef: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const fixType = (val, valType) => {
  const numericTypes = ['number', 'rating', 'variable', 'calculated'];
  let fixedVal;

  if (numericTypes.includes(valType.toLowerCase())) {
    fixedVal = Number.parseFloat(val);
    if (Number.isNaN(fixedVal)) {
      fixedVal = 0;
    }
  } else if (props.fieldDef.type === 'Checkbox') {
    fixedVal = !!val;
  } else {
    fixedVal = val.toString();
  }
  return fixedVal;
};

const localValue = ref(fixType('', props.fieldDef.type));

const selectOptions = computed(() => (props.fieldDef?.options ? props.fieldDef.options.toString().split(',').map((o) => o.toString().trim()) : []));

onMounted(() => {
  // localValue.value = fixType(props.modelValue, props.fieldDef.type);
  localValue.value = props.modelValue === null ? '' : props.modelValue;
});

// Watch for changes to props.modelValue and update localValue accordingly
watch(() => props.modelValue, (newVal) => {
  localValue.value = fixType(newVal, props.fieldDef.type);
}, { deep: true });

watch(localValue, (newVal) => {
  // let val;
  //
  // const numericTypes = ['number', 'rating', 'variable', 'calculated'];
  //
  // if (numericTypes.includes(props.fieldDef.type.toLowerCase())) {
  //   val = Number.parseFloat(newVal);
  // } else if (props.fieldDef.type === 'Checkbox') {
  //   val = !!newVal;
  // } else {
  //   val = newVal.toString();
  // }
  //
  // localValue.value = val;
  localValue.value = fixType(newVal, props.fieldDef.type);

  emit('update:modelValue', localValue.value);
});

</script>

<template>
  <div>
    <form-textarea v-if="fieldDef.type === 'Long Text'" v-model="localValue" :label="fieldDef.label" :disabled="disabled" :info="fieldDef.description || 'no description'" />
    <form-number v-else-if="fieldDef.type === 'Number' || fieldDef.type === 'Variable'" v-model="localValue" :label="fieldDef.label" :disabled="disabled" :info="fieldDef.description || 'no description'" />
    <form-checkbox v-else-if="fieldDef.type === 'Checkbox'" v-model="localValue" :label="fieldDef.label" :disabled="disabled" :info="fieldDef.description || 'no description'" />
    <form-select v-else-if="fieldDef.type === 'Select'" v-model="localValue" :options="selectOptions" :label="fieldDef.label" :disabled="disabled" :info="fieldDef.description || 'no description'" />
    <form-rating v-else-if="fieldDef.type === 'Rating'" v-model="localValue" :label="fieldDef.label" :disabled="disabled" :info="fieldDef.description || 'no description'" />
    <form-text v-else v-model="localValue" :label="fieldDef.label" :disabled="disabled" :info="fieldDef.description || 'no description'" />
  </div>
</template>

<style scoped>

</style>
