import pluralize from 'pluralize';
import { openDB, deleteDB } from 'idb';

const dbName = 'CharbroilDB';

const normalizeEntityName = (entity) => {
  const entityName = entity.toLowerCase();

  return pluralize.isPlural(entityName) ? entityName : pluralize.plural(entityName);
};

// eslint-disable-next-line require-await
const setupDB = async () => openDB(dbName, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('systems')) {
      db.createObjectStore('systems', { keyPath: 'id' });
    }
    if (!db.objectStoreNames.contains('characters')) {
      db.createObjectStore('characters', { keyPath: 'id' });
    }
    if (!db.objectStoreNames.contains('users')) {
      db.createObjectStore('users', { keyPath: 'id' });
    }
  },
});

export const addObject = async (entityType, object) => {
  const storeName = normalizeEntityName(entityType);
  const db = await setupDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.add(object);
  await tx.done;
  db.close();
};

export const addObjects = async (entityType, objects) => {
  const storeName = normalizeEntityName(entityType);
  const db = await setupDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);

  // for (const object of objects) {
  //   await store.add(object);
  // }
  await Promise.all(objects.map((o) => store.add(o)));

  await tx.done;
  db.close();
};

export const getObject = async (entityType, id) => {
  const storeName = normalizeEntityName(entityType);
  const db = await setupDB();
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const object = await store.get(id);
  await tx.done;
  db.close();
  return object;
};

export const getAllObjects = async (entityType) => {
  const storeName = normalizeEntityName(entityType);
  const db = await setupDB();
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const objects = await store.getAll();
  await tx.done;
  db.close();
  return objects;
};

export const clear = async (entityType) => {
  const storeName = normalizeEntityName(entityType);
  const db = await setupDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.clear();
  db.close();
  await tx.done;
};

export const deleteDatabase = async () => {
  await deleteDB(dbName, {
    blocked(currentVersion, event) {
      console.log('BLOCKED', currentVersion, event);
    },
  });
};
