import { defineStore } from 'pinia';

const useErrorStore = defineStore('errors', {
  state: () => ({
    error: null,
    hasError: false,
  }),
  getters: {
    errorMessage: (state) => state?.error?.message || state?.error?.toString() || 'unknown error',
  },
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    handleError(err) {
      // Assuming err can be an instance of an Error or an Axios response error
      const statusCode = err.response ? err.response.status : null;

      if (statusCode !== 401) {
        this.error = err;
        this.hasError = true;
      } else {
        // Handle 401 errors differently or ignore them
        // For example, you could set a specific message or flag related to authentication issues
        // Or simply do nothing, allowing your interceptor logic to attempt a token refresh
      }
    },
    clearError() {
      this.error = null;
      this.hasError = false;
    },
    handleFailedResponse(response, operation) {
      const result = response.data;
      if (!result.success) {
        // 401 just tells us their token is bad/missing.  We handle this elsewhere.
        if (result.statusCode !== 401) {
          this.error = `Error ${operation}: ${result.error}` || 'An error has occurred.';
          this.hasError = true;
        }
      }
    },
  },
});

export default useErrorStore;
